import { Box, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import { Droppable } from "react-beautiful-dnd";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { icons } from "../../../assets";
import { StyledPagesContainer, StyledPagesDroppable } from "../styles";
import EmptyPageScreen from "./EmptyPageScreen";
import PageFieldItem from "./PageFieldItem";
function Pages({ data }: any) {
  const dispatch = useDispatch();
  const { control, reset, setValue } = useForm();

  const elementRef = useRef<HTMLDivElement | null>(null);
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      if (!elementRef.current) return;

      // let elementScrollHeight = elementRef.current.scrollHeight;

      // window.scrollTo({
      //   top: elementScrollHeight,
      //   behavior: "smooth",
      // });
    }
    didMountRef.current = true;
  }, [data]);

  return (
    <StyledPagesContainer ref={elementRef}>
      <Box minHeight={500}>
        <Box sx={{ borderBottom: "1px solid #22222226", padding: "20px" }}>
          <Typography variant="h2" sx={{ fontSize: "16px" }}>
            {data?.title}
          </Typography>
        </Box>
        <Droppable droppableId="formbuilder-page-fields">
          {(provided: any, snapshot: any) => {
            return (
              <form>
                <StyledPagesDroppable
                  ref={(ref: any) => {
                    provided.innerRef(ref);
                  }}
                >
                  {data?.fields?.map((item: any, index: number) => {
                    return (
                      <PageFieldItem
                        control={control}
                        item={item}
                        key={item?._id}
                        index={index}
                      />
                    );
                  })}
                  {data?.fields?.length == 0 && (
                    <EmptyPageScreen image={icons.AddPic} />
                  )}
                </StyledPagesDroppable>
              </form>
            );
          }}
        </Droppable>
      </Box>
    </StyledPagesContainer>
  );
}

export default Pages;
