import { Button } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { icons } from "../assets";

const AddItemButton = ({ title, func }: any) => {
  return (
    <div>
      <Button
        variant="contained"
        startIcon={<img src={icons?.Add} />}
        sx={{ padding: "14px 18px", borderRadius: "5px" }}
        onClick={func}
      >
        {title}
      </Button>
    </div>
  );
};

export default AddItemButton;
