//@ts-nocheck
import { Box, Button, Modal, Typography } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { icons } from "../../assets";
import FileUpload from "./FileUpload/FileUpload";
import "./UploadModal.css";

export const UploadModal = ({ setOpen, img, title, section, type }: any) => {
  const [file, setFile] = useState();
  const removeFile = (filename: any) => {
    setFile(file.filter((file: any) => file.name !== filename));
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    borderRadius: "10px",
  };

  const handleClose = () => setOpen(false);
  const handleSubmit = async () => {
    if (file && file?.selectedFile) {
      const formData = {
        file: file?.selectedFile,
      };
      try {
        const response = await axios({
          method: "post",
          url: "https://api-tssoca.7t6.in/users/import",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        toast.success("Successfully uploaded");
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <Modal
      // eslint-disable-next-line no-restricted-globals
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ borderBottom: "1px solid #E5E5E4" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "28px 28px 15px 28px",
            }}
          >
            <Typography sx={{ fontSize: "16px", fontFamily: "Inter_semibold" }}>
              {title}
            </Typography>

            <Typography
              onClick={() => handleClose()}
              id="modal-modal-description"
              sx={{
                fontSize: "14px",
                fontFamily: "Inter_semibold",
                opacity: "0.5",
                cursor: "pointer",
              }}
            >
              Cancel{" "}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ padding: "28px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              marginTop: "-15px",
              marginBottom: "5px",
              textDecoration: "none",
            }}
            gap={"5px"}
            component="a"
            download
            href="https://jss-tssoca-bucket.s3.ap-south-1.amazonaws.com/employee-sample.xlsx"
          >
            <Typography variant="subtitle2" sx={{ fontSize: "14px" }}>
              Download Sample File
            </Typography>
            <img
              src={icons.Download}
              style={{ width: "17px", height: "17px" }}
            />
          </Box>
          <FileUpload
            onChange={(v: any) => setFile({ ...file, selectedFile: v })}
            name="sheet"
            accept=".xls,.xlsx"
          />
        </Box>
        <Box
          sx={{
            padding: "28px",
            paddingTop: "0px",
            paddingLeft: "0px",
            paddingRight: "0px",
            marginTop: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          gap={"10px"}
        >
          <Button
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "5px",
              padding: "10px 15px",
            }}
            variant={"contained"}
            onClick={() => handleSubmit()}
          >
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Inter_semibold",
                color: "white",
                fontSize: "12px",
              }}
            >
              Upload
            </Typography>
          </Button>
          <Button
            sx={{
              backgroundColor: "#F2F2F2",
              display: "flex",
              alignItems: "center",
              borderRadius: "5px",
              padding: "10px 15px",

              "&:hover": {
                backgroundColor: "#F2F2F2",
              },
            }}
            onClick={() => handleClose()}
          >
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Inter_semibold",
                color: "black",
                fontSize: "12px",
              }}
            >
              Cancel
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
