//@ts-nocheck
import { Box } from "@mui/material";
import FormAutoComplete from "../../../components/FormFields/FormAutocomplete";
import FormInput from "../../../components/FormFields/FormInput";
import FormRadio from "../../../components/FormFields/FormRadio";
import FormSelect from "../../../components/FormFields/FormSelect";

const FileUpload = (props: any) => {
  const { control } = props;

  return (
    <>
      <Box mt={2}>
        <FormInput name="label" label="Question Title" control={control} />
      </Box>
      <Box mt={2}>
        <FormSelect
          control={control}
          name="subType"
          multiple
          options={[
            { label: "PDF", value: "PDF" },
            { label: "JPG", value: "IMAGE" },
            { label: "XLS", value: "MIXED" },
          ]}
          label="Upload File Types"
        />
      </Box>

      <Box mt={2}>
        <FormRadio
          row
          control={control}
          name="required"
          label="Mandatory"
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
        />
      </Box>
    </>
  );
};

export default FileUpload;
