import { http } from "../../api/http";

const fetchAssetsData = ({ queryKey }: any) => {
  return http.get(`/kpi`, { params: { ...queryKey[1] } });
};

const fetchFinance = () => {
  return http.get(`/finance`);
};

const fetchKpiUpdates = ({ queryKey }: any) => {
  return http.get(`/kpi/form-response/${queryKey[1]}`, {
    params: { ...queryKey[2] },
  });
};

const fetchStatus = ({ queryKey }: any) => {
  return http.get(`/points/status`, { params: { ...queryKey[1] } });
};

export { fetchAssetsData, fetchFinance, fetchKpiUpdates, fetchStatus };
