import { createTheme, ThemeOptions } from "@mui/material/styles";

export const theme: ThemeOptions = createTheme({
  palette: {
    primary: {
      main: "#00063A",
      light: "rgba(0, 6, 58, 0.1)",
      dark: "rgba(0, 6, 58, 0.8)",
    },
    warning: {
      main: "#000000",
      light: "rgba(0, 0, 0, 0.1)",
      dark: "rgba(0, 0, 0, 0.8)",
    },
    info: {
      main: "#F2F2F2",
      light: "rgb(242, 242, 242, 0.1)",
      dark: "rgb(242, 242, 242, 1)",
    },
    background: {
      default: "#ffffff",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          fontFamily: "Inter_medium",
          fontSize: "13px",
          borderRadius: "5px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: "Inter_regular",
          color: "#000000",
          [`& fieldset`]: {
            borderRadius: 5,
          },
          // ["& input::placeholder"]: {
          //   // color: "#000000",
          //   fontSize: "12px",
          // },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          fontFamily: "Inter_regular",
          color: "#000000",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: "Inter_regular",
          color: "#000000",
          opacity: "70%",
          fontSize: "12px",
        },
        shrink: ({ ownerState }) => ({
          ...(ownerState.shrink && {
            fontSize: "0.9rem !important",
            // top: "-1 !important",
          }),
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        formControl: {
          fontFamily: "Inter_regular",
          color: "#000000",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "Inter_regular",
          color: "#000000",
          fontSize: "13px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: "0px 2px 10px #0000001A",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0px 5px 20px #0000001A",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: "0px 5px 20px #0000001A",
          borderRadius: "8px",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: "Inter_regular",
          color: "#000000",
          fontSize: "11px",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          fontFamily: "Inter_regular",
          color: "#000000",
        },
        option: {
          fontFamily: "Inter_regular",
          color: "#000000",
          fontSize: "12px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#000000",
          fontFamily: "Inter_regular",
        },
        h1: {
          color: "#000000",
          fontFamily: "Inter_bold",
        },
        h2: {
          color: "#000000",
          fontFamily: "Inter_bold",
        },
        h3: {
          color: "#000000",
          fontFamily: "Inter_bold",
        },
        h4: {
          color: "#000000",
          fontFamily: "Inter_bold",
          fontSize: "38px",
        },
        h5: {
          color: "#000000",
          fontFamily: "Inter_semibold",
          fontSize: "18px",
        },
        h6: {
          color: "#000000",
          fontFamily: "Inter_semibold",
          fontSize: "14px",
        },
        subtitle1: {
          color: "#000000",
          fontFamily: "Inter_semibold",
          fontSize: "22px",
        },
        subtitle2: {
          color: "#000000",
          fontFamily: "Inter_medium",
          fontSize: "12px",
        },
        body1: {
          color: "#000000",
          fontFamily: "Inter_medium",
          fontSize: "14px",
          opacity: "95%",
        },
        body2: {
          color: "#000000",
          fontFamily: "Inter_medium",
          opacity: "85%",
          fontSize: "12px",
        },
        caption: {
          color: "#000000",
          fontFamily: "Inter_regular",
          fontSize: "10px",
          opacity: "65%",
        },
      },
    },
  },
});
