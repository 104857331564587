import MoreVertIcon from "@mui/icons-material/MoreVert";
import SummarizeIcon from "@mui/icons-material/Summarize";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const DividerBanner = ({ details, role }: any) => {
  const arr = [
    { name: "Employee Name", value: details?.name },
    { name: "Role", value: role?.role?.name },
    { name: "Section", value: details?.section?.name },
    { name: "Office", value: details?.office?.name },
    { name: "Head quarters", value: details?.location?.name },
  ];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigateClick = () => {
    localStorage.setItem(
      "empDetails",
      JSON.stringify({
        name: details?.name,
        role: role?.role?.name,
        roleID: role?.roleId,
        updatedAt: details?.updatedAt,
        division: details?.location?.name,
      })
    );
    navigate("/employee-kpi-management/performance");
  };

  const handleRedirectionClick = () => {
    localStorage.setItem(
      "empDetails",
      JSON.stringify({
        name: details?.name,
        id: details?._id,
        role: role?.role?.name,
        roleID: role?.roleId,
        updatedAt: details?.updatedAt,
        division: details?.location?.name,
      })
    );
    navigate("/employee-kpi-management/annual-performance");
  };

  const navigate = useNavigate();
  return (
    <div>
      <Box
        sx={{
          boxShadow: "0px 5px 15px #22222214",
          padding: "36px 40px",
          marginRight: "15px",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "space-between",
          border: "1px solid white;",
          alignItems: "center",
          "&: hover": {
            border: "1px solid #00063A;",
          },
        }}
      >
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={6.5}
        >
          {arr?.map(
            (item: {
              name:
                | string
                | number
                | boolean
                | React.ReactElement<
                    any,
                    string | React.JSXElementConstructor<any>
                  >
                | React.ReactFragment
                | React.ReactPortal
                | null
                | undefined;
              value:
                | string
                | number
                | boolean
                | React.ReactElement<
                    any,
                    string | React.JSXElementConstructor<any>
                  >
                | React.ReactFragment
                | React.ReactPortal
                | null
                | undefined;
            }) => {
              return (
                <Box>
                  <Typography
                    variant="subtitle2"
                    sx={{ opacity: "0.5", fontSize: "13px" }}
                  >
                    {item?.name}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{ fontSize: "16px", marginTop: "7px" }}
                  >
                    {item?.value}
                  </Typography>
                </Box>
              );
            }
          )}
        </Stack>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{
            "&:hover": {
              background: "white",
            },
          }}
        >
          <Box
            sx={{
              padding: "2px 4px",
              border: "1px solid #00063A29",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MoreVertIcon sx={{ color: "grey" }} />
          </Box>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => handleNavigateClick()}>
            <Box sx={{ display: "flex", alignItems: "center" }} gap={"10px"}>
              <VisibilityIcon
                sx={{
                  color: "!important #00063A",
                  width: "18px",
                  height: "18px",
                }}
              />
              <Typography
                variant="h6"
                sx={{ fontSize: "12px", color: "#00063A" }}
              >
                View Performance
              </Typography>
            </Box>
          </MenuItem>
          <MenuItem onClick={() => handleRedirectionClick()}>
            <Box sx={{ display: "flex", alignItems: "center" }} gap={"10px"}>
              <SummarizeIcon
                sx={{
                  color: "!important #00063A",
                  width: "18px",
                  height: "18px",
                }}
              />
              <Typography
                variant="h6"
                sx={{ fontSize: "12px", color: "#00063A" }}
              >
                Annual Performance Report
              </Typography>
            </Box>
          </MenuItem>
        </Menu>
      </Box>
    </div>
  );
};

export default DividerBanner;
