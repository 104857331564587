//@ts-nocheck
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { http } from "../../api/http";
import ReportingManager from "./ReportingManager";

const Form = ({ data, toggleDrawer }: any) => {
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState("");
  const queryClient = useQueryClient();

  const [sections, setSections] = useState([]);
  const [offices, setOffices] = useState([]);
  const [locations, setLocations] = useState([]);
  const [state, setState] = useState<any>([]);

  useEffect(() => {
    if (data?.userRoles?.length) {
      let value = data?.userRoles?.map((i) => {
        return {
          manager: { label: i?.manager?.name, value: i?.manager?._id },
          managerId: i?.manager?._id,
          managerRole: {
            label: i?.managerRole?.name,
            value: i?.managerRole?._id,
          },
          managerRoleId: i?.managerRole?._id,
          label: i?.role?.name,
          value: i?.roleId,
          roleId: i?.roleId,
        };
      });
      setState(value);
    }
  }, [data]);

  useEffect(() => {
    http.get(`/locations`).then((res) => {
      setLocations(res?.data?.data);
    });
  }, []);

  useEffect(() => {
    http.get(`/sections`).then((res) => {
      setSections(res?.data?.data);
    });
  }, []);

  useEffect(() => {
    http.get(`/office`).then((res) => {
      setOffices(res?.data?.data);
    });
  }, []);

  useEffect(() => {
    data && setEdit(true);
    data && setId(data?._id);
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      name: data?.name,
      employeeId: data?.employeeId,
      mobileNumber: data?.mobileNumber,
      sectionId: data?.section,
      officeId: data?.office,
      locationId: data?.location,
    },
  });

  const onSubmit = (data: any) => {
    if (edit) {
      const formData = {
        ...data,
        sectionId: data?.sectionId?._id,
        locationId: data?.locationId?._id,
        officeId: data?.officeId?._id,
        userRoles: state,
      };

      http
        .patch(`/users/${id}`, formData)
        .then((res) => {
          toast.success("The Employee details have been updated");
          queryClient.invalidateQueries("empConf");

          toggleDrawer(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } else {
      const formData = {
        ...data,
        sectionId: data?.sectionId?._id,
        locationId: data?.locationId?._id,
        officeId: data?.officeId?._id,
        userRoles: state,
      };
      http
        .post(`/users`, formData)
        .then(() => {
          toast.success("The record has been created");
          queryClient.invalidateQueries("empConf");
          toggleDrawer(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  return (
    <div>
      {" "}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            type={"text"}
            {...register("name", {
              required: true,
              pattern: { value: /^[ A-Za-z0-9()[]+.-*/ % /]*$/ },
            })}
            label={"Employee Name"}
            required={true}
            fullWidth={true}
            variant={"standard"}
            sx={{ marginTop: "24px" }}
          />
          {errors?.name?.type == "required" && (
            <Typography sx={{ color: "red" }}>
              This field is required
            </Typography>
          )}
          {errors?.name?.type == "pattern" && (
            <Typography sx={{ color: "red" }}>Invalid Name</Typography>
          )}
          <TextField
            type={"text"}
            {...register("employeeId", {
              required: true,
              pattern: { value: /^[A-Za-z,\-0-9\/ .]+$/ },
            })}
            label={"Employee ID"}
            required={true}
            fullWidth={true}
            variant={"standard"}
            sx={{ marginTop: "24px" }}
          />
          {errors?.employeeId?.type == "required" && (
            <Typography sx={{ color: "red" }}>
              This field is required
            </Typography>
          )}
          {errors?.employeeId?.type == "pattern" && (
            <Typography sx={{ color: "red" }}>Invalid Name</Typography>
          )}
          <TextField
            type={"text"}
            {...register("mobileNumber", {
              required: true,
              pattern: /^\d{10}$/,
            })}
            label={"Employee Mobile Number"}
            required={true}
            sx={{ marginTop: "24px" }}
            fullWidth={true}
            variant={"standard"}
          />
          {errors?.mobileNumber?.type == "required" && (
            <Typography
              sx={{
                fontFamily: "Inter_medium",
                color: "red",
              }}
            >
              This field is required
            </Typography>
          )}
          {errors?.mobileNumber?.type == "pattern" && (
            <Typography
              sx={{
                fontFamily: "Inter_medium",
                color: "red",
              }}
            >
              Invalid Mobile Number
            </Typography>
          )}

          <Controller
            control={control}
            name="sectionId"
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  id="country-select-demo"
                  sx={{ marginTop: "25px" }}
                  options={sections}
                  defaultValue={value}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required={true}
                      // fullWidth
                      {...params}
                      label="Select Unit"
                      variant="standard"
                      inputProps={{
                        ...params.inputProps,
                        // className: params?.classes?.input,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                  onChange={(_, data) => {
                    onChange(data);
                  }}
                  // defaultValue={value}
                />
              );
            }}
          />

          <Controller
            control={control}
            name="officeId"
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  id="country-select-demo"
                  sx={{ marginTop: "25px" }}
                  options={offices}
                  defaultValue={value}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required={true}
                      // fullWidth
                      {...params}
                      label="Select Office"
                      variant="standard"
                      inputProps={{
                        ...params.inputProps,
                        // className: params?.classes?.input,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                  onChange={(_, data) => {
                    onChange(data);
                  }}
                  // defaultValue={value}
                />
              );
            }}
          />

          <Controller
            control={control}
            name="locationId"
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  id="country-select-demo"
                  sx={{ marginTop: "25px" }}
                  options={locations}
                  defaultValue={value}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required={true}
                      // fullWidth
                      {...params}
                      label="Select Location"
                      variant="standard"
                      inputProps={{
                        ...params.inputProps,
                        // className: params?.classes?.input,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                  onChange={(_, data) => {
                    onChange(data);
                  }}
                  // defaultValue={value}
                />
              );
            }}
          />
          <ReportingManager data={data} state={state} setState={setState} />
          <Button
            type="submit"
            variant="contained"
            sx={{ marginTop: "24px" }}
            disabled={state?.length === 0 ? true : false}
          >
            Submit
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default Form;
