import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { icons } from "../../assets";
import Loader from "../../components/Loader";
import PieChartCard from "../../components/PieChart";
import EmptyScreen from "../KPIManagement/EmptyScreen";

const CategorizedUpdates = ({ locations, offices, loading }: any) => {
  const COLORS2 = ["#0078FF", "#FF8A25", "#FFD75F"];

  const COLORS1 = [
    "#FF8A25",
    "#0078FF",
    "#00ECC2",
    "#FFD75F",
    "#10FF00",
    "#FF4359",
  ];

  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <Grid container spacing={3} sx={{ marginTop: "50px" }}>
        <Grid item xs={6}>
          <Card
            title={"Total Updates by Location"}
            data={locations}
            colors={COLORS1}
          />
        </Grid>
        <Grid item xs={6}>
          <Card
            title={"Total Updates by Office"}
            data={offices}
            colors={COLORS2}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CategorizedUpdates;

const Card = ({ title, data, colors }: any) => {
  return (
    <>
      <Box
        sx={{
          borderRadius: "10px",
          boxShadow: "0px 5px 15px #2D388B1A;",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#E9F1FC",
            padding: "15px",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            border: "1px solid #0E0E0E1A",
          }}
        >
          <Typography sx={{ fontSize: "14px", fontFamily: "Inter_medium" }}>
            {title}
          </Typography>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              padding: "0px 10px",
            }}
          >
            {data?.length == 0 ? (
              <Box sx={{ marginTop: "30px" }}>
                <EmptyScreen image={icons?.Growth} marginTop={false} />
              </Box>
            ) : (
              <PieChartCard data={data} colors={colors} />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
