import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import { icons } from "../../assets";
import Loader from "../../components/Loader";
import EmptyScreen from "../KPIManagement/EmptyScreen";

const InitialMetrics = ({ emps, roles, updates, loading }: any) => {
  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <Grid
        container
        spacing={4}
        sx={{
          marginTop: "40px",
        }}
      >
        <Grid item xs={4}>
          <MetricsBox
            title="Number of Employees"
            img={icons.Groups}
            desc={"Total number of Employees"}
            count={emps ?? "NA"}
          />
        </Grid>
        <Grid item xs={4}>
          <MetricsBox
            title="Number of Roles"
            img={icons.RoleMetrics}
            desc={"Total number of Roles"}
            count={roles ?? "NA"}
          />
        </Grid>
        <Grid item xs={4}>
          <MetricsBox
            title="KPI Updates"
            img={icons.KPIUpdates}
            desc={"Total KPIs updated"}
            count={updates ?? "NA"}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default InitialMetrics;

const MetricsBox = ({ title, img, desc, count }: any) => {
  return (
    <Box
      sx={{
        borderRadius: "10px",
        boxShadow: "0px 5px 15px #2D388B1A;",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#F4F1DE",
          padding: "15px",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        }}
      >
        <Typography sx={{ fontSize: "14px", fontFamily: "Inter_medium" }}>
          {title}
        </Typography>
      </Box>
      {!count ? (
        <Box sx={{ marginTop: "30px" }}>
          <EmptyScreen image={icons?.Growth} marginTop={false} />
        </Box>
      ) : (
        <Box sx={{ padding: "23px" }}>
          <Box sx={{ marginTop: "10px" }}>
            <img src={img} />
          </Box>
          <Typography
            sx={{
              fontFamily: "Inter_medium",
              fontSize: "16px",
              opacity: "0.7",
              marginTop: "25px",
            }}
          >
            {desc}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Inter_semibold",
              fontSize: "38px",
              marginTop: "8px",
            }}
          >
            {count}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
