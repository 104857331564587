//@ts-nocheck
import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { updateField } from "../../../api/services/forms";
import DrawerWrapper from "../../../components/DrawerWrapper/DrawerWrapper";
import { selectForms, setData } from "../../../redux/formsSlice";
import { getAddFieldDefaultValues } from "../utils/generateAddFieldDefaultValues";
import {
  FormBuildertypes,
  renderFieldsComponent,
} from "../utils/renderFieldsComponent";

const FieldProperties = ({ open, setOpen, item }: any) => {
  const kpiId = localStorage.getItem("kpiId");
  const dispatch = useDispatch();

  const queryClient = useQueryClient();
  const params = useParams();
  const { data } = useSelector(selectForms);

  const { mutate, isLoading } = useMutation(updateField, {
    onSuccess: () => {
      toast.success("Field properties updated");
      setOpen(false);
      queryClient.invalidateQueries("form-details");
    },
    onError: (err: any) => {
      toast.error(err.response.data.message);
    },
  });

  const { watch, control, handleSubmit, setValue, error, reset } = useForm({
    defaultValues: getAddFieldDefaultValues(item),
    mode: "onChange",
  });

  const onSubmit = (result: any) => {
    let apiData = { ...result };

    // if (item?.type === FormBuildertypes.DATE) {
    //   if (apiData.allowedDates === "CUSTOM") {
    //     apiData.dateRange = {
    //       startDate: apiData.startDate,
    //       endDate: apiData.endDate,
    //     };
    //   }
    // }

    // if (item?.type === FormBuildertypes.DECISION_BOX) {
    //   apiData.decisionText = {
    //     checkedText: apiData.checkedText,
    //     uncheckedText: apiData.uncheckedText,
    //   };
    // }

    if (item?.type === FormBuildertypes.FILE_UPLOAD) {
      const res = {
        label: apiData?.label,
        required: apiData?.required,
        type: "FILE_UPLOAD",
        subType: apiData?.subType,
        _id: item._id,
        type: "FILE_UPLOAD",
      };
      let fields = _.cloneDeep(data?.fields) || [];
      const index = fields.findIndex((el) => el?._id === res?._id);
      fields[index] = res;

      const result = { ...data, fields: fields };
      dispatch(setData(result));
      setOpen(false);
    }

    // if (item?.type === FormBuildertypes.NAME) {
    //   let title = apiData.inputs?.find(
    //     (item: any) => item.inputType === "TITLE"
    //   );
    //   title.options = apiData.titleOptions;
    // }

    if (item?.type === FormBuildertypes.INPUT) {
      const res = {
        label: apiData?.label,
        required: apiData?.required,
        type: "INPUT",
        subType: apiData?.subType,
        _id: item._id,
        type: "INPUT",
      };
      let fields = _.cloneDeep(data?.fields) || [];
      const index = fields.findIndex((el) => el?._id === res?._id);
      fields[index] = res;

      const result = { ...data, fields: fields };
      dispatch(setData(result));
      setOpen(false);
    }

    if (item?.type === FormBuildertypes.CHECKBOX) {
      const res = {
        label: apiData?.label,
        required: apiData?.required,
        subType: "CHECKLIST",
        options: apiData?.options,
        _id: item._id,
        type: "CHECKBOX",
      };
      let fields = _.cloneDeep(data?.fields) || [];
      const index = fields.findIndex((el) => el?._id === res?._id);
      fields[index] = res;

      const result = { ...data, fields: fields };
      dispatch(setData(result));
      setOpen(false);
    }

    //   if (apiData.validation) {
    //     let validation = validations?.find(
    //       (item: any) => item?._id === apiData.validation
    //     );
    //     apiData.validation = {
    //       id: validation?._id,
    //       format: validation?.format,
    //       message: validation?.message,
    //     };
    //   }
    // }

    // mutate({
    //   formId: params.formId,
    //   pageId: data?.pages?.[activePage]?._id,
    //   fieldId: item._id,
    //   data: apiData,
    // });
  };

  // useEffect(() => {
  //   reset({ ...setValue() });
  // }, []);

  return (
    <DrawerWrapper open={open} setOpen={setOpen} title="Properties">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ "& div:first-of-type": { mt: 0 } }}>
          {renderFieldsComponent(item, control, watch, setValue, reset)}
          <Box
            display="flex"
            justifyContent="flex-start"
            sx={{ marginTop: "10px" }}
          >
            <Button type="submit" variant="contained">
              Update
            </Button>
          </Box>
        </Box>
      </form>
    </DrawerWrapper>
  );
};

export default FieldProperties;
