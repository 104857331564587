//@ts-nocheck
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import FormInput from "../../../components/FormFields/FormInput";
import FormRadio from "../../../components/FormFields/FormRadio";
import FormSelect from "../../../components/FormFields/FormSelect";

interface Props {
  item: any;
  control: any;
  setValue: any;
  reset: any;
}

const SingleLine = (props: Props) => {
  const { control } = props;
  const dispatch = useDispatch();

  // const { data }: ResType = useQuery("form-validations", getFormValidations, {
  //   onSuccess: (res: any) => {

  //     dispatch(setValidations(res?.data));
  //   },
  // });

  return (
    <>
      <Box mt={2}>
        <FormInput name="label" label="Question Title" control={control} />
      </Box>

      <Box mt={2}>
        <FormSelect
          control={control}
          name="subType"
          label="Select Data type"
          options={[
            { label: "Number", value: "NUMBER" },
            { label: "Text", value: "TEXT" },
            { label: "String", value: "MIXED" },
          ]}
        />
      </Box>

      <Box mt={2}>
        <FormRadio
          row
          control={control}
          name="required"
          label="Mandatory"
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
        />
      </Box>
    </>
  );
};

export default SingleLine;
