import { Close } from "@mui/icons-material";
import {
  alpha,
  AppBar,
  Box,
  Drawer,
  IconButton,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";

interface IDrawerWrapperProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  children: React.ReactNode;
  width?: number;
}

const StyledDrawerHeader = styled(Box)(({ theme }: any) => ({
  height: "64px",
  backgroundColor: alpha(theme.palette.text.secondary, 0.1),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0.6rem 1rem",
}));

const StyledDrawerContent = styled(Box)(({ theme }) => ({
  padding: "1rem",
  height: "calc(100vh - 64px)",
  overflowY: "auto",
  paddingBottom: "4rem",
}));

function DrawerWrapper(props: IDrawerWrapperProps) {
  const { open, setOpen, title, children, width = 550 } = props;

  return (
    <Drawer
      anchor="right"
      PaperProps={{ sx: { width } }}
      open={open}
      onClose={() => setOpen(false)}
    >
      <StyledDrawerHeader>
        <Typography fontWeight={600}>{title}</Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontFamily: "Inter_semibold",
            color: "black",
            opacity: "0.5",
            cursor: "pointer",
          }}
          onClick={() => setOpen(false)}
        >
          Cancel
        </Typography>
      </StyledDrawerHeader>
      <StyledDrawerContent>{children}</StyledDrawerContent>
    </Drawer>
  );
}

export default DrawerWrapper;
