//@ts-nocheck
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { http } from "../../api/http";
import DividerBanner from "../../components/DividerBanner";
import Loader from "../../components/Loader";

const EmployeeRoles = () => {
  const [employeeDetails, setEmployeeDetails] = useState({});
  const empID = localStorage.getItem("empID");

  const fetchAssets = async (id: any) => {
    const res = await http.get(`/users/roles/${id}`);

    setEmployeeDetails({ ...res?.data });

    // setTableData(res?.data?.data);
    // setCount(res?.data?.count);
  };

  const { data, isLoading } = useQuery(["rolList", empID], () =>
    fetchAssets(empID)
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Typography variant="subtitle1">Select Employee Role</Typography>
      {employeeDetails?.userRoles?.map((item: any) => {
        return (
          <Box sx={{ marginTop: "40px" }}>
            <DividerBanner details={employeeDetails} role={item} />
          </Box>
        );
      })}
    </div>
  );
};

export default EmployeeRoles;
