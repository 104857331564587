//@ts-nocheck
import CloseIcon from "@mui/icons-material/Close";
import DraftsIcon from "@mui/icons-material/Drafts";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PublishIcon from "@mui/icons-material/Publish";
import {
  alpha,
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getForm, updatePage } from "../../api/services/forms";
import Breadcrumbs from "../../components/Breadcrumbs";
import Loader from "../../components/Loader";
import PageHeader from "../../components/PageHeader";
import { selectForms, setData } from "../../redux/formsSlice";
import { ResType } from "../../types";
import Fields from "../../views/forms/fields";
import FormAppbar from "../../views/forms/FormAppbar";
import FormInfo from "../../views/forms/FormInfo";
import Pages from "../../views/forms/pages";
import availableFields from "../../views/forms/utils/availableFields";
import { prepareField } from "../../views/forms/utils/prepareField";

const FormBuilder = () => {
  const kpiId = localStorage.getItem("kpiId");
  const roleName = localStorage.getItem("roleName");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = useSelector(selectForms);
  const queryClient = useQueryClient();

  const { isLoading }: ResType = useQuery(["form-details", kpiId], getForm, {
    onSuccess: (res) => {
      dispatch(setData(res?.data));
    },
  });

  const { mutate: updatePageFields } = useMutation(updatePage, {
    onSuccess: () => {
      toast.success("Page fields updated");
      queryClient.invalidateQueries("form-details");
    },
    onError: (err: any) => {
      toast.error(err.response.data.message);
    },
  });

  const onDragEnd = (result: any) => {
    const { source, destination } = result;

    if (!destination) return;

    if (destination.droppableId === "formbuilder-available-fields") return;

    if (source.droppableId === destination.droppableId) {
      if (source.index === destination.index) return;
      let reordered = reorder(data?.fields, source.index, destination.index);

      // updatePageFields({
      //   formId: params.formId,
      //   data: {
      //     fields: reordered,
      //   },

      // });
      const res = { ...data, fields: reordered };
      dispatch(setData(res));
    } else {
      let fields = _.cloneDeep(data?.fields) || [];

      let newField = prepareField(availableFields[source.index]);
      fields?.splice(destination.index, 0, newField);

      const res = { ...data, fields: fields };
      dispatch(setData(res));
    }
  };

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDraftClick = () => {
    let { status, fields, ...newObj } = data;
    const formData = {
      status: "Drafted",
      fields: fields,
    };
    updatePageFields({ formId: kpiId, data: formData });
  };

  const handlePublishClick = () => {
    let { status, fields, ...newObj } = data;
    const formData = {
      status: "Published",
      fields: fields,
    };
    updatePageFields({ formId: kpiId, data: formData });
  };

  const handleCloseClick = () => {
    navigate("/kpi-management/details");
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <PageHeader
          title={
            <Breadcrumbs
              links={[
                { name: "KPI Management", to: "/kpi-management" },
                {
                  name: roleName,
                  to: "/kpi-management/details",
                },
                {
                  name: "KPI",
                  to: null,
                },
              ]}
            />
          }
        />
        <Box>
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            sx={{
              marginRight: "18px",
              border: "1px solid #DEDEDE",
              backgroundColor:
                data?.status == "Drafted" ? "#FFB700" : "#3A8B63",
              color: data?.status == "Drafted" ? "Black" : "White",
              "&:hover": {
                backgroundColor:
                  data?.status == "Drafted" ? "#FFB700" : "#3A8B63",
                color: data?.status == "Drafted" ? "Black" : "White",
              },
            }}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {data?.status == "Drafted" ? "Drafted" : "Published"}
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {data?.status == "Drafted" ? (
              <MenuItem onClick={handlePublishClick} disableRipple>
                <PublishIcon />
                Publish
              </MenuItem>
            ) : (
              <MenuItem onClick={handleDraftClick} disableRipple>
                <DraftsIcon />
                Draft
              </MenuItem>
            )}
            <MenuItem onClick={handleCloseClick} disableRipple>
              <CloseIcon />
              CloseForm
            </MenuItem>
          </StyledMenu>
        </Box>
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        {data?.type === "TEMPLATE" && <FormAppbar />}

        <Grid container spacing={2} sx={{ pb: 4, px: 2 }}>
          <Grid item xs={7}>
            <Pages data={data} />
          </Grid>
          {data?.status == "Drafted" && (
            <Grid item xs={5}>
              <FormInfo data={data} />
              <Fields />
            </Grid>
          )}
        </Grid>
      </DragDropContext>
    </>
  );
};

export default FormBuilder;

const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
