//@ts-nocheck
import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { PencilButton } from "../../../components/IconButtons/IconButtons";
import Form from "./form";

const Details = () => {
  const urlPath = window.location.pathname;
  const [form, setForm, details] = useOutletContext();
  const update = form ? "Update" : "";
  const [cropDetails, setCropDetails] = useState({});
  const [cropInfo, setCropInfo] = useState([]);
  const [title, setTitle] = useState("");

  useEffect(() => {
    details?.length == 0
      ? setCropInfo(JSON.parse(localStorage.getItem("details")))
      : setCropInfo(details);
  }, []);

  useEffect(() => {
    setCropInfo(details);
  }, [details]);

  useEffect(() => {
    setTitle(
      urlPath === "/settings/crop-types-configuration/cutoff-dates/rabi"
        ? "Rabi"
        : "Kharif"
    );
  }, [urlPath]);

  useEffect(() => {
    form === true && setForm(false);
  }, [title]);

  const kharifDetails = cropInfo?.seasons?.filter(
    (item) => item?.name == "Kharif"
  );

  const rabiDetails = cropInfo?.seasons?.filter((item) => item?.name == "Rabi");

  useEffect(() => {
    title === "Kharif"
      ? setCropDetails(kharifDetails ? kharifDetails[0] : {})
      : setCropDetails(rabiDetails ? rabiDetails[0] : {});
  }, [title, cropInfo]);

  return (
    <div>
      <Box
        sx={{
          marginTop: "30px",
          border: "1px solid #ECECF0",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#FBF9F2",
            padding: "30px",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h5"
            sx={{ whiteSpace: "nowrap" }}
          >{`${update} ${title} Cutoff Dates`}</Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <PencilButton onClick={() => setForm(true)} />
          </Box>
        </Box>
        {form ? (
          <Form details={cropDetails} />
        ) : (
          <Grid
            container
            spacing={2}
            rowSpacing={5}
            sx={{ padding: "30px", paddingBottom: "195px" }}
          >
            <Grid item xs={2.4}>
              <Typography
                variant="h6"
                sx={{ opacity: "0.5", fontSize: "12px" }}
              >
                Duration
              </Typography>
              <Typography variant="h5" sx={{ fontSize: "16px" }}>
                {cropDetails?.duration ? `${cropDetails?.duration} days` : "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography
                variant="h6"
                sx={{ opacity: "0.5", fontSize: "12px" }}
              >
                Source Verification
              </Typography>
              <Typography variant="h5" sx={{ fontSize: "16px" }}>
                {cropDetails?.sourceVerification
                  ? moment(cropDetails?.sourceVerification).format("DD-MM-YYYY")
                  : "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography
                variant="h6"
                sx={{ opacity: "0.5", fontSize: "12px" }}
              >
                Sowing Date
              </Typography>
              <Typography variant="h5" sx={{ fontSize: "16px" }}>
                {cropDetails?.sowingDate
                  ? moment(cropDetails?.sowingDate).format("DD-MM-YYYY")
                  : "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography
                variant="h6"
                sx={{ opacity: "0.5", fontSize: "12px" }}
              >
                Registrations of Areas
              </Typography>
              <Typography variant="h5" sx={{ fontSize: "16px" }}>
                {cropDetails?.registrationOfAreas
                  ? moment(cropDetails?.registrationOfAreas).format(
                      "DD-MM-YYYY"
                    )
                  : "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography
                variant="h6"
                sx={{ opacity: "0.5", fontSize: "12px" }}
              >
                Number of Field Inspections
              </Typography>
              <Typography variant="h5" sx={{ fontSize: "16px" }}>
                {cropDetails?.fieldInspections
                  ? cropDetails?.fieldInspections?.length
                  : "NA"}
              </Typography>
            </Grid>
            {cropDetails?.fieldInspections?.map((obj, ind) => {
              return (
                <Grid item xs={2.4}>
                  <Typography
                    variant="h6"
                    sx={{ opacity: "0.5", fontSize: "12px" }}
                  >
                    {`Field Inspection ${ind + 1}`}
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: "16px" }}>
                    {moment(obj).format("DD-MM-YYYY")}
                  </Typography>
                </Grid>
              );
            })}

            <Grid item xs={2.4}>
              <Typography
                variant="h6"
                sx={{ opacity: "0.5", fontSize: "12px" }}
              >
                Issuance of TCs
              </Typography>
              <Typography variant="h5" sx={{ fontSize: "16px" }}>
                {cropDetails?.issuanceOfTc
                  ? moment(cropDetails?.issuanceOfTc).format("DD-MM-YYYY")
                  : "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography
                variant="h6"
                sx={{ opacity: "0.5", fontSize: "12px" }}
              >
                Processing/Sampling Date
              </Typography>
              <Typography variant="h5" sx={{ fontSize: "16px" }}>
                {cropDetails?.processingDate
                  ? moment(cropDetails?.processingDate).format("DD-MM-YYYY")
                  : "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Typography
                variant="h6"
                sx={{ opacity: "0.5", fontSize: "12px" }}
              >
                Final Certification
              </Typography>
              <Typography variant="h5" sx={{ fontSize: "16px" }}>
                {cropDetails?.certificationDate
                  ? moment(cropDetails?.certificationDate).format("DD-MM-YYYY")
                  : "NA"}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Box>
    </div>
  );
};

export default Details;
