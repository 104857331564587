//@ts-nocheck
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import {
  Breadcrumbs as MuiBreadcrumbs,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React, { ReactNode } from "react";
import { Link, useNavigate } from "react-router-dom";

interface BreadcrumbsProps {
  links: { to: string | null; name: string | ReactNode }[];
  icon?: ReactNode;
  color?: string;
}

const StyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  "& .MuiSvgIcon-root": {
    fontSize: "26px",
  },
  "& .MuiTypography-root": {
    fontSize: "16px",
    fontWeight: 600,
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "black",
  opacity: "0.6",
  transition: "color 0.2s ease-in-out",
  fontFamily: "Inter_medium",
  fontSize: "15px",
  "&:hover": {
    opacity: "1",
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: 0,
  marginRight: "5px",
  marginLeft: "-2rem",
}));

export default function Breadcrumbs({ links, icon, color }: BreadcrumbsProps) {
  const navigate = useNavigate();
  let linksArray = links.slice(0, -1);
  let currentPage = links.slice(-1)[0];
  const backLink = links.slice(-2)[0];

  return (
    <StyledStack direction={"row"} alignItems="center">
      {linksArray?.length ? (
        <StyledIconButton
          onClick={() => {
            navigate(backLink.to);
          }}
        >
          {icon ? icon : <ChevronLeft />}
        </StyledIconButton>
      ) : null}
      <MuiBreadcrumbs separator={<Typography variant="h3">/</Typography>}>
        {linksArray.map((link, index) => (
          <StyledLink to={link.to} key={index}>
            {link?.name}
          </StyledLink>
        ))}
        <Typography sx={{ fontFamily: "Inter_medium", fontSize: "15px" }}>
          {currentPage?.name}
        </Typography>
      </MuiBreadcrumbs>
    </StyledStack>
  );
}
