//@ts-nocheck
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { http } from "../../../api/http";

const Form = ({ data, toggleDrawer }: any) => {
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState();
  const [financialYears, setFinancialYears] = useState([]);

  const yearsArray = data?.financialYear?.map((obj, ind) => {
    return obj;
  });

  const [yearIds, setYearIds] = useState(yearsArray ? yearsArray : []);

  useEffect(() => {
    http.get(`/finance`).then((res) => {
      setFinancialYears(res?.data?.data);
    });
  }, []);

  useEffect(() => {
    data && setEdit(true);
    data && setId(data?._id);
  }, []);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      name: data?.name,
      startDate: data?.startDate ? new Date(data?.startDate) : new Date(),
      endDate: data?.endDate ? new Date(data?.endDate) : new Date(),
      status: data?.status,
    },
  });

  const queryClient = useQueryClient();

  const onTagsChange = (event, values) => {
    setYearIds(values);
  };

  const onSubmit = async (submitData: any) => {
    if (edit) {
      const formData = {
        ...submitData,
        startDate: moment(new Date(submitData?.startDate))?.format(
          "YYYY-MM-DD"
        ),
        endDate: moment(new Date(submitData?.endDate))?.format("YYYY-MM-DD"),
        status: submitData?.status === "true",
        financialYearId: yearIds?.map((item) => item?._id),
      };

      http
        .patch(`/seasons/${id}`, formData)
        .then((res) => {
          toast.success("The details have been updated");
          queryClient.invalidateQueries("seasonsConf");
          toggleDrawer(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } else {
      const formData = {
        ...submitData,
        startDate: moment(new Date(submitData?.startDate))?.format(
          "YYYY-MM-DD"
        ),
        endDate: moment(new Date(submitData?.endDate))?.format("YYYY-MM-DD"),
        status: submitData?.status === "true",

        financialYearId: yearIds?.map((item) => item?._id),
      };
      http
        .post(`/seasons`, formData)
        .then((res) => {
          toast.success("The record has been created");
          queryClient.invalidateQueries("seasonsConf");
          toggleDrawer(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  return (
    <div>
      {" "}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            type={"text"}
            {...register("name", {
              required: true,
              pattern: { value: /^[A-Za-z,\-0-9 .]+$/ },
            })}
            label={"Season Name"}
            required={true}
            fullWidth={true}
            variant={"standard"}
            sx={{ marginTop: "24px" }}
          />
          {errors?.name?.type == "required" && (
            <Typography sx={{ color: "red" }}>
              This field is required
            </Typography>
          )}
          {errors?.name?.type == "pattern" && (
            <Typography sx={{ color: "red" }}>Invalid Name</Typography>
          )}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ marginTop: "24px" }}>
              <Controller
                control={control}
                name="startDate"
                render={({
                  field: { ref, onBlur, name, ...field },
                  fieldState,
                }) => {
                  return (
                    <DatePicker
                      {...field}
                      label="Season Start Date"
                      inputFormat={"DD-MMM-YYYY"}
                      renderInput={(inputProps) => (
                        <TextField
                          {...inputProps}
                          helperText={null}
                          fullWidth={true}
                          required={true}
                          variant={"standard"}
                        />
                      )}
                    />
                  );
                }}
              />
            </Box>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ marginTop: "24px" }}>
              <Controller
                control={control}
                name="endDate"
                render={({
                  field: { ref, onBlur, name, ...field },
                  fieldState,
                }) => (
                  <DatePicker
                    {...field}
                    label="Season End Date"
                    inputFormat={"DD-MMM-YYYY"}
                    renderInput={(inputProps) => (
                      <TextField
                        {...inputProps}
                        helperText={null}
                        fullWidth={true}
                        required={true}
                        variant={"standard"}
                      />
                    )}
                  />
                )}
              />
            </Box>
          </LocalizationProvider>

          <Autocomplete
            multiple
            id="tags-outlined"
            options={financialYears}
            value={yearIds}
            getOptionLabel={(option) => option?.title}
            filterSelectedOptions
            onChange={onTagsChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Financial Years *"
                variant="standard"
                sx={{ marginTop: "24px" }}
              />
            )}
          />
          <Box sx={{ marginTop: "24px" }}>
            <FormLabel id="status">Status</FormLabel>
            <Controller
              name="status"
              control={control}
              defaultValue={data?.status}
              render={({ field }) => (
                <RadioGroup
                  row
                  aria-aria-labelledby="status"
                  {...field}
                  onChange={(e) => field.onChange(e.target.value)}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Enabled"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Disabled"
                  />
                </RadioGroup>
              )}
            />
          </Box>
          <Button type="submit" variant="contained" sx={{ marginTop: "24px" }}>
            Submit
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default Form;
