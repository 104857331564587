//@ts-nocheck
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Drawer,
  IconButton,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";

import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import AddItemButton from "../../components/AddItemButton";
import {
  StyledDrawerContent,
  StyledDrawerHeader,
} from "../../components/DrawerTemplate";
import { EditButton } from "../../components/IconButtons";
import { DeleteButton } from "../../components/IconButtons/IconButtons";
import Loader from "../../components/Loader";
import Table from "../../components/TableComponent";
import Form from "./form";
import { UploadModal } from "../../components/UploadModal/UploadModal";
import { icons } from "../../assets";
import EmptyScreen from "../../components/EmptyScreen";
import { http } from "../../api/http";

const EmployeeConfiguration = () => {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [formData, setFormData] = useState([]);
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const [modalOpen, setModalOpen] = useState(false);

  const toggleDrawer = (newOpen: any) => {
    setOpen(newOpen);
  };

  const handleEditClick = (row: any) => {
    setFormData(row);
    toggleDrawer(true);
  };
  const [search, setSearch] = useState("");

  const fetchAssets = async (search: string, page: number) => {
    const res = await http.get(
      `/users?search=${search}&limit=10&offset=${
        page > 0 ? (page - 1) * 10 : page * 10
      }`
    );

    setTableData(res?.data?.data);
    setCount(res?.data?.count);
  };

  const { data, isLoading } = useQuery(["empConf", search, page], () =>
    fetchAssets(search, page)
  );

  const handleDelete = (id) => {
    http
      .delete(`/users/${id}`)
      .then((res) => {
        queryClient.invalidateQueries("empConf");
      })
      .catch((err) => {
        enqueueSnackbar(err?.response?.data?.message);
      });
  };

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Employee ID",
      dataIndex: "employeeId",
      key: "employeeId",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
    },

    {
      title: "Role",

      render: (_, colData) => {
        if (colData?.userRoles?.length === 0 || !colData?.userRoles) {
          return "NA";
        }
        const roles = colData?.userRoles?.map((obj, ind) => {
          return obj?.role?.name;
        });
        const res = roles?.join(", ");
        return res;
      },
    },
    {
      title: "Reporting Manager",

      render: (_, colData) => {
        if (colData?.userRoles?.length === 0 || !colData?.userRoles) {
          return "NA";
        }
        const roles = colData?.userRoles?.map((obj, ind) => {
          return obj?.manager ? obj?.manager?.name : "NA";
        });
        const res = roles?.join(", ");
        return res;
      },
    },
    {
      title: "Section",
      dataIndex: "section.name",
      key: "section.name",
      render: (_, colData) => {
        return colData.section ? colData?.section?.name : "NA";
      },
    },
    {
      title: "Office",
      dataIndex: "office.name",
      key: "office.name",
      render: (_, colData) => {
        return colData.office ? colData?.office?.name : "NA";
      },
    },
    {
      title: "Location",
      dataIndex: "location.name",
      key: "location.name",
      render: (_, colData) => {
        return colData.location ? colData?.location?.name : "NA";
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_, row) => {
        return (
          <Box sx={{ display: "flex" }} gap={"18px"}>
            <EditButton
              onClick={() => {
                handleEditClick(row);
              }}
            />
            <DeleteButton onClick={() => handleDelete(row._id)} />
          </Box>
        );
      },
    },
  ];

  function handlePagination(e, v) {
    setPage(v);
  }

  return (
    <div>
      {modalOpen && (
        <UploadModal
          setOpen={setModalOpen}
          title={"Employees"}
          section={"employees"}
        />
      )}{" "}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }} gap={"10px"}>
          <Typography variant="subtitle1">Employee Details</Typography>
          <form onSubmit={() => {}}>
            <TextField
              label="Search by Employee Name"
              sx={{ width: "300px", padding: "0px" }}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => {}}>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              value={search}
              onChange={(e) => setSearch(e?.target?.value)}
            />
          </form>
        </Box>
        <Box sx={{ display: "flex" }} gap={"10px"}>
          <Box>
            <AddItemButton
              title="Import Employees"
              func={() => {
                setModalOpen(true);
              }}
            />
          </Box>
          <AddItemButton
            title="Add New Employee"
            func={() => {
              setFormData(null);
              toggleDrawer(true);
            }}
          />
        </Box>
      </Box>
      {isLoading || loading || !tableData ? (
        <Loader />
      ) : tableData?.length == 0 ? (
        <center>
          <EmptyScreen
            title={"Employee"}
            word={"employees"}
            image={icons.EmptyEmployee}
          />
        </center>
      ) : (
        <Box sx={{ marginTop: "20px" }}>
          <Table
            key={"s"}
            dataSource={tableData}
            rowKey={"id"}
            columns={columns}
            loading={isLoading}
          />
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Pagination
              count={count > 10 ? Math.ceil(count / 10) : 1}
              variant="outlined"
              shape="rounded"
              onChange={handlePagination}
              page={page}
            />{" "}
          </Box>
        </Box>
      )}
      <Drawer
        anchor="right"
        open={open}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <StyledDrawerHeader>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Inter_semibold",
              color: "black",
            }}
          >
            {formData ? "Edit Employee details" : "Add Employee"}
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontFamily: "Inter_semibold",
              color: "black",
              opacity: "0.5",
              cursor: "pointer",
            }}
            onClick={() => toggleDrawer(false)}
          >
            Cancel
          </Typography>
        </StyledDrawerHeader>
        <StyledDrawerContent>
          <Box sx={{ width: "550px" }}>
            <Form data={formData} toggleDrawer={toggleDrawer} />
          </Box>
        </StyledDrawerContent>
      </Drawer>
    </div>
  );
};

export default EmployeeConfiguration;
