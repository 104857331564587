//@ts-nocheck
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { http } from "../../api/http";

const Form = ({ data, toggleDrawer }: any) => {
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState("");
  const queryClient = useQueryClient();

  useEffect(() => {
    setEdit(true);
    setId(data?.data?._id);
  }, []);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      totalPoints: data?.data?.points,
      percentage: data?.data?.percentage,
    },
  });

  const totalPoints = watch("totalPoints");
  const percentage = watch("percentage");
  const awardedPoints = totalPoints * (percentage / 100);

  const onSubmit = (data: any) => {
    http
      .put(`/points/${id}`, data)
      .then((res) => {
        toast.success("The details have been updated");
        queryClient.invalidateQueries("points");
        toggleDrawer(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth sx={{ marginTop: "24px" }}>
          <TextField
            type={"number"}
            inputProps={{ min: 0 }}
            {...register("totalPoints", {
              required: true,
            })}
            variant={"standard"}
            label={"Total points"}
            required={true}
          />
        </FormControl>
        {errors?.totalPoints && (
          <Typography
            sx={{
              fontFamily: "Inter_medium",
              color: "red",
            }}
          >
            This field is required
          </Typography>
        )}
        <FormControl fullWidth sx={{ marginTop: "24px" }}>
          <InputLabel id="percentage">Grading percentage *</InputLabel>
          <Select
            labelId="percentage"
            id="percentage"
            label="Grading percentage *"
            {...register("percentage", { required: true })}
            defaultValue={data?.data?.percentage}
            required={true}
            variant={"standard"}
          >
            <MenuItem value={"0"}>{"0%"}</MenuItem>
            <MenuItem value={"40"}>{"40%"}</MenuItem>
            <MenuItem value={"60"}>{"60%"}</MenuItem>
            <MenuItem value={"80"}>{"80%"}</MenuItem>
            <MenuItem value={"100"}>{"100%"}</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ marginTop: "24px" }}>
          <TextField
            type={"number"}
            inputProps={{ min: 0 }}
            value={awardedPoints}
            variant={"standard"}
            label={"Points Awarded"}
            required={true}
          />
        </FormControl>
        {errors?.pointsAwarded && (
          <Typography
            sx={{
              fontFamily: "Inter_medium",
              color: "red",
            }}
          >
            This field is required
          </Typography>
        )}
        <Button type="submit" variant="contained" sx={{ marginTop: "24px" }}>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default Form;
