import { Box, CircularProgress, Typography } from "@mui/material";
import { useState } from "react";
import { icons } from "../../../assets";

function FileUpload({
  name,
  onChange,
  label = "+ Select the XL document",
  ...props
}: any) {
  let [fileName, setFileName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState("");

  function handleChange(e: any) {
    if (e.target.files[0]) {
      setFileName(e.target.files[0].name);
      onChange(e.target.files[0]);
    }
  }

  return (
    <>
      <label htmlFor={name}>
        <Box
          borderRadius={8}
          textAlign="center"
          style={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
          }}
          py={2}
          width="740px"
          height="309px"
          border="3px dashed #2B2D2229;"
          gap={1}
        >
          {fileName && <img src={icons.Excel} />}
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Inter_semibold",
              opacity: "0.5",
            }}
          >
            {fileName ? fileName : label}
          </Typography>
        </Box>
      </label>
      <input
        onChange={(e) => handleChange(e)}
        type="file"
        style={{ display: "none" }}
        id={name}
        accept=".xls,.xlsx"
        {...props}
      />
    </>
  );
}

export default FileUpload;
