import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

import { http } from "../../api/http";
import Loader from "../../components/Loader";
import CategorizedUpdates from "./CategorizedUpdates";
import InitialMetrics from "./InitialMetrics";
import PerformerTable from "./PerformerTable";
import UpdatesBySeason from "./UpdatesBySeason";
import UpdatesBySection from "./UpdatesBySection";

const Dashboard = () => {
  const [FYs, setFYs] = useState([]);
  const [FYID, setFYID] = useState<any>(localStorage?.getItem("financialYear"));
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [apply, setApply] = useState<boolean>(false);

  useEffect(() => {
    setApply(true);
  }, []);
  useEffect(() => {
    http
      .get("/finance")
      .then((res) => {
        setFYs(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }, []);

  const fetchAssets = async (id: any) => {
    const res = await http.get(`/dashboard/admin?financialYearId=${id}`);
    return res?.data;
  };

  const { data: stats, isLoading: statsLoading } = useQuery(
    ["dashboardStatistics", FYID],
    () => fetchAssets(FYID)
  );

  const fetchTopPerformers = async () => {
    return await http.get(
      `/dashboard/admin/top-performers?financialYearId=${FYID}&startDate=${
        startDate ?? ""
      }&endDate=${endDate ?? ""}`
    );
  };

  const { data: topPerformers, isLoading: topPerformersLoading } = useQuery(
    ["topPerformers", FYID],
    fetchTopPerformers,

    {
      onSuccess: () => {
        setApply(false);
      },

      enabled: !!apply,
    }
  );

  return (
    <div
      style={{
        padding: "6px",
        marginBottom: "50px",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <Typography variant="subtitle1">Dashboard</Typography>
        <FormControl
          sx={{
            width: "280px",
          }}
        >
          <InputLabel id="frequency">Select Financial Year</InputLabel>
          <Select
            labelId="financialYear"
            id="financialYear"
            label="Select Financial Year"
            sx={{
              borderRadius: "5px",
            }}
            value={FYID}
            onChange={(e) => {
              setFYID(e?.target?.value);
              setApply(true);
            }}
          >
            {FYs?.map((item: any) => {
              return <MenuItem value={item?._id}>{item?.title}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Box>
      {!stats && statsLoading ? (
        <Loader />
      ) : (
        <>
          <InitialMetrics
            emps={stats?.users}
            roles={stats?.roles}
            updates={stats?.kpiUpdates}
            loading={statsLoading}
          />
          <CategorizedUpdates
            locations={stats?.locations}
            offices={stats?.offices}
            loading={statsLoading}
          />
          <UpdatesBySection sections={stats?.sections} loading={statsLoading} />
          <UpdatesBySeason seasons={stats?.seasons} loading={statsLoading} />
        </>
      )}

      <>
        <PerformerTable
          performers={topPerformers?.data}
          loading={topPerformersLoading}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setApply={setApply}
        />
      </>
    </div>
  );
};

export default Dashboard;
