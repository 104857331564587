import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Cell, Legend, Pie, PieChart } from "recharts";

const PieChartCard = ({ data, colors }: any) => {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 1500px)").matches
  );

  const RADIAN = Math.PI / 180;

  useEffect(() => {
    window
      .matchMedia("(min-width: 1500px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const renderLegend = (props: any) => {
    const { payload } = props;
    return (
      <>
        {matches && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "450px",
              // marginTop: "-20px",
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  {payload.map((entry: any, index: any) => (
                    <Grid item xs={12} key={index}>
                      <span
                        style={{
                          padding: "10px 0px",
                          // paddingLeft: '12px',
                          display: "flex",
                          alignItems: "center",
                        }}
                        key={`item-${index}`}
                      >
                        <div
                          style={{
                            display: "inline-block",
                            width: "12px",
                            marginRight: "6px",
                            height: "12px",
                            backgroundColor: entry.color,
                            borderRadius: "3px",
                          }}
                        ></div>
                        <Typography>{entry.value}</Typography>
                      </span>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
        {!matches && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "340px",
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  {payload.map((entry: any, index: any) => (
                    <Grid item xs={12} key={index}>
                      <span
                        style={{
                          padding: "10px 50px",

                          display: "flex",
                          alignItems: "center",
                        }}
                        key={`item-${index}`}
                      >
                        <div
                          style={{
                            display: "inline-block",
                            width: "12px",
                            marginRight: "6px",
                            height: "12px",
                            borderRadius: "3px",
                            backgroundColor: entry.color,
                          }}
                        ></div>
                        <Typography>{entry.value}</Typography>
                      </span>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  };
  return (
    <PieChart width={700} height={400} style={{ fontFamily: "Inter_regular" }}>
      <Pie
        data={data}
        cx={matches ? 220 : 270}
        cy={200}
        outerRadius={matches ? 110 : 65}
        paddingAngle={0}
        dataKey="count"
        stroke="none"
        label
      >
        {data?.map((entry: any, index: number) => (
          <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
        ))}
      </Pie>

      <Legend
        content={renderLegend}
        iconType={"square"}
        align={"right"}
        verticalAlign={"middle"}
      />
    </PieChart>
  );
};

export default PieChartCard;
