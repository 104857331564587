import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  fetchAssetsData,
  fetchFinance,
  fetchKpiUpdates,
  fetchStatus,
} from "../../../api/services/employeePerformance";
import Breadcrumbs from "../../../components/Breadcrumbs";
import PageHeader from "../../../components/PageHeader";
import KPICards from "./KPICards";
import KPIDetails from "./KPIDetails";

const EmployeePerformance = () => {
  const [FYID, setFYID] = useState<any>(localStorage?.getItem("financialYear"));
  const [status, setStatus] = useState("");
  const [serachKpi, setSearchKpi] = useState("");
  const [kpi, setKpi] = useState<any>();
  const [storageDetails, setStorageDetails] = useState<any>();
  const empID = localStorage.getItem("empID");
  const empDetails = JSON.parse(localStorage.getItem("empDetails") ?? "");

  useEffect(() => {
    setStorageDetails({
      empID: empID,
      empDetails: empDetails,
    });
  }, []);

  const { data: kpiData, isLoading: kpiLoading } = useQuery(
    [
      "Assets",
      {
        search: serachKpi,
        roleId: storageDetails?.empDetails?.roleID,
        status: "Published",
        platform: "WEB",
      },
    ],
    fetchAssetsData
  );

  const { data: fyData } = useQuery(["finance"], fetchFinance);

  const { data: kpiUpdate, isLoading: kpiUpdatesLoading } = useQuery(
    [
      "kpiUpdatesList",
      kpi?._id,
      {
        userId: storageDetails?.empID,
        financialYearId: FYID,
        type: kpi?.calculationFrequency,
        value: status,
      },
    ],
    fetchKpiUpdates,
    {
      enabled:
        ((kpi?.calculationFrequency === "CutoffDate" ||
          kpi?.calculationFrequency === "Monthly") &&
          Boolean(status)) ||
        kpi?.calculationFrequency === "Annually",
    }
  );

  const { data: kpistatus } = useQuery(
    [
      "kpistatus",
      {
        type:
          kpi?.calculationFrequency === "CutoffDate"
            ? "Seasonal"
            : kpi?.calculationFrequency,
        userId: storageDetails?.empID,
        roleId: storageDetails?.empDetails?.roleID,
        kpiId: kpi?._id,
        financialYearId: FYID,
      },
    ],
    fetchStatus,
    {
      enabled:
        kpi?.calculationFrequency === "CutoffDate" ||
        kpi?.calculationFrequency === "Monthly",
    }
  );

  return (
    <div>
      <Box display="flex" justifyContent="space-between">
        <PageHeader
          title={
            <Breadcrumbs
              links={[
                {
                  name: "Employee KPI Management",
                  to: "/employee-kpi-management",
                },
                {
                  name: "Roles",
                  to: "/employee-kpi-management/roles",
                },
                {
                  name: "Employee Performance",
                  to: null,
                },
              ]}
            />
          }
        />
      </Box>
      <Box
        sx={{
          boxShadow: "0px 4px 16px #313B7E14",
          padding: "36px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "10px",
        }}
      >
        <Box>
          <Typography
            variant="subtitle2"
            sx={{ opacity: "0.5", fontSize: "13px" }}
          >
            Employee Name
          </Typography>
          <Typography variant="h5" sx={{ fontSize: "16px" }}>
            {storageDetails?.empDetails?.name}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="subtitle2"
            sx={{ opacity: "0.5", fontSize: "13px" }}
          >
            Role
          </Typography>
          <Typography variant="h5" sx={{ fontSize: "16px" }}>
            {storageDetails?.empDetails?.role}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="subtitle2"
            sx={{ opacity: "0.5", fontSize: "13px" }}
          >
            Division
          </Typography>
          <Typography variant="h5" sx={{ fontSize: "16px" }}>
            {storageDetails?.empDetails?.division}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="subtitle2"
            sx={{ opacity: "0.5", fontSize: "13px" }}
          >
            Last Updated Time
          </Typography>
          <Typography variant="h5" sx={{ fontSize: "16px" }}>
            {moment(storageDetails?.empDetails?.updatedAt).format(
              "DD-MMM-YYYY, hh:mm A"
            )}
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <KPICards
            cards={kpiData?.data?.data}
            kpi={kpi}
            setKpi={setKpi}
            isLoading={kpiLoading}
            serachKpi={serachKpi}
            setSearchKpi={setSearchKpi}
          />
        </Grid>
        <Grid item xs={8}>
          <Box>
            <Box mt={3} display="flex">
              <Box>
                <FormControl
                  sx={{
                    width: "270px",
                  }}
                >
                  <InputLabel id="frequency">Select Financial Year</InputLabel>
                  <Select
                    labelId="financialYear"
                    id="financialYear"
                    label="Select Financial Year"
                    sx={{
                      borderRadius: "5px",
                    }}
                    value={FYID}
                    onChange={(e) => {
                      setFYID(e?.target?.value);
                    }}
                  >
                    {fyData?.data?.data?.map((item: any) => {
                      return (
                        <MenuItem value={item?._id}>{item?.title}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                {(kpi?.calculationFrequency === "CutoffDate" ||
                  kpi?.calculationFrequency === "Monthly") && (
                  <Box>
                    <TextField
                      select
                      sx={{ width: "180px", ml: 1 }}
                      label={
                        kpi?.calculationFrequency === "CutoffDate"
                          ? "Select Season"
                          : "Select Month"
                      }
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      {kpi?.calculationFrequency === "CutoffDate"
                        ? kpistatus?.data?.map((i: any) => (
                            <MenuItem value={i.seasonId}>{i?.season}</MenuItem>
                          ))
                        : kpistatus?.data?.map((i: any) => (
                            <MenuItem value={i.month}>{i?.month}</MenuItem>
                          ))}
                    </TextField>
                  </Box>
                )}
              </Box>
            </Box>
            <KPIDetails
              kpiUpdates={kpiUpdate?.data}
              isLoading={kpiUpdatesLoading}
              kpi={kpi}
              FYID={FYID}
              setStatus={setStatus}
              status={status}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default EmployeePerformance;
