//@ts-nocheck
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  IconButton,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { SetStateAction, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { http } from "../../api/http";
import { icons } from "../../assets";
import EmptyScreen from "../../components/EmptyScreen";
import Loader from "../../components/Loader";
import Table from "../../components/TableComponent";

const EmployeeKPIManagement = () => {
  const [count, setCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const [modalOpen, setModalOpen] = useState(false);

  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  const buttonSX = {
    "&:hover": {
      borderColor: "#24272C",
      color: "#24272C",
      background: "none",
    },
    fontSize: "15px",
    fontFamily: "Inter_semibold",
    borderRadius: "10px",
    borderColor: "#24272C",
    color: "#24272C",
  };

  const fetchAssets = async (search: string, page: number) => {
    const res = await http.get(
      `/users?search=${search}&limit=10&offset=${
        page > 0 ? (page - 1) * 10 : page * 10
      }`
    );

    setTableData(res?.data?.data);
    setCount(res?.data?.count);
  };

  const { data, isLoading } = useQuery(["empList", search, page], () =>
    fetchAssets(search, page)
  );

  const handleViewClick = (id: any) => {
    localStorage.setItem("empID", id);
    navigate("/employee-kpi-management/roles");
  };

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Employee ID",
      dataIndex: "employeeId",
      key: "employeeId",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
    },

    {
      title: "Role",

      render: (_, colData) => {
        if (colData?.userRoles?.length === 0 || !colData?.userRoles) {
          return "NA";
        }
        const roles = colData?.userRoles?.map((obj, ind) => {
          return obj?.role?.name;
        });
        const res = roles?.join(", ");
        return res;
      },
    },
    {
      title: "Reporting Manager",

      render: (_, colData) => {
        if (colData?.userRoles?.length === 0 || !colData?.userRoles) {
          return "NA";
        }
        const roles = colData?.userRoles?.map((obj, ind) => {
          return obj?.manager ? obj?.manager?.name : "NA";
        });
        const res = roles?.join(", ");
        return res;
      },
    },
    {
      title: "Section",
      dataIndex: "section.name",
      key: "section.name",
      render: (_: any, colData: { section: { name: any } }) => {
        return colData.section ? colData?.section?.name : "NA";
      },
    },
    {
      title: "Office",
      dataIndex: "office.name",
      key: "office.name",
      render: (_: any, colData: { office: { name: any } }) => {
        return colData.office ? colData?.office?.name : "NA";
      },
    },
    {
      title: "Location",
      dataIndex: "location.name",
      key: "location.name",
      render: (_: any, colData: { location: { name: any } }) => {
        return colData.location ? colData?.location?.name : "NA";
      },
    },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_: any, row: any) => {
        return (
          <Box
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => handleViewClick(row?._id)}
            gap={"3.5px"}
          >
            <VisibilityIcon
              sx={{
                color: "!important #00063A",
                width: "18px",
                height: "18px",
              }}
            />
            <Typography variant="h6" sx={{ whiteSpace: "nowrap" }}>
              View Roles
            </Typography>
          </Box>
        );
      },
    },
  ];

  function handlePagination(e: any, v: SetStateAction<number>) {
    setPage(v);
  }
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle1">Employees</Typography>
        <form onSubmit={() => {}}>
          <TextField
            label="Search by Employee Name"
            sx={{ width: "450px", padding: "0px" }}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
            value={search}
            onChange={(e) => setSearch(e?.target?.value)}
          />
        </form>
      </Box>
      {isLoading || !tableData ? (
        <Loader />
      ) : tableData?.length == 0 ? (
        <center>
          <EmptyScreen word={"employees"} image={icons.EmptyEmployee} />
        </center>
      ) : (
        <Box sx={{ marginTop: "20px" }}>
          <Table
            key={"s"}
            dataSource={tableData}
            rowKey={"id"}
            columns={columns}
            loading={isLoading}
          />
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Pagination
              count={count > 10 ? Math.ceil(count / 10) : 1}
              variant="outlined"
              shape="rounded"
              onChange={handlePagination}
              page={page}
            />{" "}
          </Box>
        </Box>
      )}
    </div>
  );
};

export default EmployeeKPIManagement;
