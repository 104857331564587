import { Box, Typography } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectForms, setData } from "../../../redux/formsSlice";
import { DummyStyledField, StyledField } from "../styles";
import { prepareField } from "../utils/prepareField";

function Field({ item, index }: any) {
  const params = useParams();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { data, activePage } = useSelector(selectForms);

  // const { mutate: handleAddField } = useMutation(addField, {
  //   onSuccess: () => {
  //     toast.success("Field added");
  //     queryClient.invalidateQueries("form-details");
  //   },
  //   onError: (err: any) => {
  //     toast.error(err.response.data.message);
  //   },
  // });

  const handleAddField = (item: any) => {
    let newField = prepareField(item);
    const res = { ...data, fields: [...data?.fields, newField] };

    dispatch(setData(res));
  };

  return (
    <Draggable draggableId={item?.id.toString()} key={item?.id} index={index}>
      {(provided: any, snapshot: any) => (
        <>
          {provided.placeholder}

          {snapshot.isDragging && (
            <DummyStyledField>
              <Box>
                <img src={item.icon} alt={item.label} width={20} height={20} />
              </Box>
              <Typography variant="h6">{item.label}</Typography>
            </DummyStyledField>
          )}
          <StyledField
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isdragging={snapshot.isDragging ? 1 : 0}
            style={provided.draggableProps.style}
            onClick={() => handleAddField(item)}
          >
            <Box>
              <img src={item.icon} alt={item.label} width={20} height={20} />
            </Box>
            <Typography variant="h6">{item.label}</Typography>
          </StyledField>
        </>
      )}
    </Draggable>
  );
}

export default Field;
