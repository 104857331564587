//@ts-nocheck
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { http } from "../../../api/http";
import { icons } from "../../../assets";

const Form = ({ details }: any) => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      duration: details?.duration,
      registrationOfAreas: details?.registrationOfAreas,
      fieldInspections: details?.fieldInspections,
      issuanceOfTc: details?.issuanceOfTc,
      processingDate: details?.processingDate,
      certificationDate: details?.certificationDate,
    },
  });

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [inputFields, setInputFields] = useState(
    details?.fieldInspections ? details?.fieldInspections : []
  );

  const cropId = localStorage.getItem("itemId");
  const cutoffId = details?._id;

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index] = moment(new Date(event));
    setInputFields(data);
  };

  const addFields = () => {
    let newfield = moment(new Date());
    setInputFields([...inputFields, newfield]);
  };

  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  const onSubmit = (data: any) => {
    const formData = {
      ...data,
      fieldInspections: inputFields?.map((item) =>
        moment(item).format("YYYY-MM-DD")
      ),
      sourceVerification: moment(data?.sourceVerification).format("YYYY-MM-DD"),
      sowingDate: moment(data?.sowingDate).format("YYYY-MM-DD"),
    };
    http
      .patch(`/crops/${cropId}/cutoff/${cutoffId}`, formData)
      .then(() => {
        queryClient.invalidateQueries("cropTypeConf");
        navigate(0);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            padding: "30px",
            paddingBottom: "100px",
          }}
        >
          <Box
            sx={{ borderBottom: "1px solid #E6E7EC", paddingBottom: "30px" }}
          >
            <Grid container spacing={7}>
              <Grid item xs={2.4}>
                <TextField
                  {...register("duration")}
                  label={"Duration"}
                  fullWidth={true}
                  variant={"standard"}
                />
              </Grid>
              <Grid item xs={2.4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box>
                    <Controller
                      control={control}
                      name="sourceVerification"
                      render={({
                        field: { ref, onBlur, name, ...field },
                        fieldState,
                      }) => {
                        return (
                          <DatePicker
                            {...field}
                            label="Source Verification"
                            inputFormat={"DD-MMM-YYYY"}
                            renderInput={(inputProps) => (
                              <TextField
                                {...inputProps}
                                helperText={null}
                                fullWidth={true}
                                variant={"standard"}
                              />
                            )}
                            onChange={(value) =>
                              field.onChange(
                                moment(new Date(value)).format("YYYY-MM-DD")
                              )
                            }
                          />
                        );
                      }}
                      defaultValue={moment(new Date())}
                    />
                  </Box>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={2.4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box>
                    <Controller
                      control={control}
                      name="sowingDate"
                      render={({
                        field: { ref, onBlur, name, ...field },
                        fieldState,
                      }) => {
                        return (
                          <DatePicker
                            {...field}
                            label="Sowing Date"
                            inputFormat={"DD-MMM-YYYY"}
                            renderInput={(inputProps) => (
                              <TextField
                                {...inputProps}
                                helperText={null}
                                fullWidth={true}
                                variant={"standard"}
                              />
                            )}
                            onChange={(value) =>
                              field.onChange(
                                moment(new Date(value)).format("YYYY-MM-DD")
                              )
                            }
                          />
                        );
                      }}
                      defaultValue={moment(new Date())}
                    />
                  </Box>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={2.4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box>
                    <Controller
                      control={control}
                      name="registrationOfAreas"
                      render={({
                        field: { ref, onBlur, name, ...field },
                        fieldState,
                      }) => {
                        return (
                          <DatePicker
                            {...field}
                            label="Registration of areas"
                            inputFormat={"DD-MMM-YYYY"}
                            minDate={new Date()}
                            renderInput={(inputProps) => (
                              <TextField
                                {...inputProps}
                                helperText={null}
                                fullWidth={true}
                                variant={"standard"}
                              />
                            )}
                            onChange={(value) =>
                              field.onChange(
                                moment(new Date(value)).format("YYYY-MM-DD")
                              )
                            }
                          />
                        );
                      }}
                      defaultValue={moment(new Date())}
                    />
                  </Box>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              borderBottom: "1px solid #E6E7EC",
              paddingBottom: "30px",
              marginTop: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "13px",
              }}
              gap={"20px"}
            >
              <Typography sx={{ fontSize: "12px", fontFamily: "Inter_light" }}>
                Number of Field Inspections
              </Typography>
              <Box
                sx={{
                  padding: "8px 28px",
                  backgroundColor: "#E8E8E8",
                  borderRadius: "8px",
                }}
              >
                <Typography>{inputFields ? inputFields?.length : 0}</Typography>
              </Box>
            </Box>
            {inputFields?.length != 0 ? (
              <Grid
                container
                spacing={7}
                sx={{ display: "flex", alignItems: "center" }}
              >
                {inputFields?.map((input, index) => {
                  return (
                    <Grid item xs={2.8}>
                      <Box
                        sx={{ display: "flex", alignItems: "center" }}
                        gap={"10px"}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Box>
                            <Controller
                              control={control}
                              name="fieldInspections"
                              render={({
                                field: { ref, onBlur, name, ...field },
                                fieldState,
                              }) => {
                                return (
                                  <DatePicker
                                    {...field}
                                    label={`Field Inspection ${index + 1}`}
                                    value={moment(input)}
                                    inputFormat={"DD-MMM-YYYY"}
                                    minDate={new Date()}
                                    renderInput={(inputProps) => (
                                      <TextField
                                        {...inputProps}
                                        helperText={null}
                                        fullWidth={true}
                                        variant={"standard"}
                                      />
                                    )}
                                    onChange={(event) =>
                                      handleFormChange(index, event)
                                    }
                                  />
                                );
                              }}
                              defaultValue={moment(new Date())}
                            />
                          </Box>
                        </LocalizationProvider>
                        <Box
                          sx={{ display: "flex", alignItems: "center" }}
                          gap={"5px"}
                        >
                          {index != 0 && (
                            <Box
                              onClick={() => removeFields(index)}
                              sx={{ cursor: "pointer" }}
                            >
                              <DeleteOutlineIcon
                                sx={{
                                  width: "18px",
                                  height: "18px",
                                  paddingTop: "34px",
                                }}
                              />
                            </Box>
                          )}
                          {inputFields?.length == index + 1 && (
                            <Box
                              onClick={() => addFields()}
                              sx={{ cursor: "pointer" }}
                            >
                              <img
                                src={icons?.More}
                                style={{
                                  width: "18px",
                                  height: "18px",
                                  paddingTop: "34px",
                                }}
                              />
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Grid
                container
                spacing={7}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Grid item xs={2.4} sx={{ marginTop: "-35px" }}>
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    gap={"25px"}
                  >
                    <Box onClick={() => addFields()} sx={{ cursor: "pointer" }}>
                      <img
                        src={icons?.More}
                        style={{
                          width: "18px",
                          height: "18px",
                          paddingTop: "34px",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
          <Box
            sx={{
              borderBottom: "1px solid #E6E7EC",
              paddingBottom: "30px",
              marginTop: "30px",
            }}
          >
            <Grid container spacing={7}>
              <Grid item xs={2.4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box>
                    <Controller
                      control={control}
                      name="issuanceOfTc"
                      render={({
                        field: { ref, onBlur, name, ...field },
                        fieldState,
                      }) => {
                        return (
                          <DatePicker
                            {...field}
                            label="Issuance of TCs"
                            inputFormat={"DD-MMM-YYYY"}
                            minDate={new Date()}
                            renderInput={(inputProps) => (
                              <TextField
                                {...inputProps}
                                helperText={null}
                                fullWidth={true}
                                variant={"standard"}
                              />
                            )}
                            onChange={(value) =>
                              field.onChange(
                                moment(new Date(value)).format("YYYY-MM-DD")
                              )
                            }
                          />
                        );
                      }}
                      defaultValue={moment(new Date())}
                    />
                  </Box>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={2.4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box>
                    <Controller
                      control={control}
                      name="processingDate"
                      render={({
                        field: { ref, onBlur, name, ...field },
                        fieldState,
                      }) => {
                        return (
                          <DatePicker
                            {...field}
                            label="Processing\Sampling Date"
                            inputFormat={"DD-MMM-YYYY"}
                            minDate={new Date()}
                            renderInput={(inputProps) => (
                              <TextField
                                {...inputProps}
                                helperText={null}
                                fullWidth={true}
                                variant={"standard"}
                              />
                            )}
                            onChange={(value) =>
                              field.onChange(
                                moment(new Date(value)).format("YYYY-MM-DD")
                              )
                            }
                          />
                        );
                      }}
                      defaultValue={moment(new Date())}
                    />
                  </Box>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={2.4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box>
                    <Controller
                      control={control}
                      name="certificationDate"
                      render={({
                        field: { ref, onBlur, name, ...field },
                        fieldState,
                      }) => {
                        return (
                          <DatePicker
                            {...field}
                            label="Final Certification Date"
                            inputFormat={"DD-MMM-YYYY"}
                            minDate={new Date()}
                            renderInput={(inputProps) => (
                              <TextField
                                {...inputProps}
                                helperText={null}
                                fullWidth={true}
                                variant={"standard"}
                              />
                            )}
                            onChange={(value) => {
                              field.onChange(
                                moment(new Date(value)).format("YYYY-MM-DD")
                              );
                            }}
                          />
                        );
                      }}
                      defaultValue={moment(new Date())}
                    />
                  </Box>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "24px",
            }}
          >
            <Button type="submit" variant="contained">
              Save Changes
            </Button>
          </Box>
        </Box>
      </form>
    </div>
  );
};

export default Form;
