//@ts-nocheck
import { Edit } from "@mui/icons-material";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import EditForm from "./EditForm";

function FormInfo({ data }: any) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          border: "1px solid #22222226",
          borderRadius: "10px",
          position: "relative",
          marginBottom: "30px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#FBF9F2",
            padding: "20px",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        >
          <Typography variant="body1" sx={{ opacity: "1" }}>
            KPI Details
          </Typography>
        </Box>
        <Grid container spacing={2} rowSpacing={5} sx={{ padding: "20px" }}>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ opacity: "0.5", fontSize: "12px" }}>
              Points Allocated
            </Typography>
            <Typography variant="h5" sx={{ fontSize: "14px" }}>
              {data?.pointsAllocated}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ opacity: "0.5", fontSize: "12px" }}>
              Frequency
            </Typography>
            <Typography variant="h5" sx={{ fontSize: "14px" }}>
              {data?.frequency}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ opacity: "0.5", fontSize: "12px" }}>
              Grading Criteria
            </Typography>
            <Typography variant="h5" sx={{ fontSize: "14px" }}>
              {data?.calculationFrequency}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ opacity: "0.5", fontSize: "12px" }}>
              Buffer days for grading
            </Typography>
            <Typography variant="h5" sx={{ fontSize: "14px" }}>
              {data?.bufferDays}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default FormInfo;
