//@ts-nocheck
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { icons } from "../../assets";
import Loader from "../../components/Loader";
import EmptyScreen from "../KPIManagement/EmptyScreen";

const UpdatesBySeason = ({ seasons, loading }: any) => {
  const navigate = useNavigate();

  const data = seasons?.map((item: any) => {
    return {
      name: item?.name,
      "Total Updates done": item?.count,
    };
  });

  const CustomTick = (props: any) => {
    const { x, y, payload } = props;

    return (
      <g>
        <foreignObject x={x - 215} y={y - 19} width={160} height={140}>
          <div
            style={{
              height: "46px",
              border: "1px solid #13131329",
              backgroundColor: "#FBFBFB",
              padding: "0px 12px",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography variant="body1" sx={{ opacity: 1 }}>
              {payload?.value.substring(0, payload?.value.indexOf(" "))}
            </Typography>
            <Typography>
              {payload?.value.substring(payload?.value.indexOf(" ") + 1)}
            </Typography>
          </div>
        </foreignObject>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            padding: "7px",
            backgroundColor: "white",
            border: "none",
            borderRadius: "5px",
            boxShadow: "0px 3px 13px #2A32711A",
          }}
        >
          <Typography variant="subtitle2" sx={{ opacity: "0.5" }}>
            {payload[0].name}{" "}
          </Typography>
          <Box display="flex">
            <Typography variant="h6">
              {payload[0].value.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </Typography>
            {payload[0]?.payload?.currency && (
              <Typography ml={1} variant="h6">
                {payload[0]?.payload?.currency}
              </Typography>
            )}
          </Box>
        </div>
      );
    }

    return null;
  };

  const renderLegend = (props: any) => {
    const { payload } = props;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "7px",
        }}
      >
        {payload.map((entry: any, index: any) => (
          <span
            style={{ padding: "10px", display: "flex", alignItems: "center" }}
            key={`item-${index}`}
          >
            <div
              style={{
                display: "inline-block",
                width: "12px",
                marginRight: "4px",
                height: "12px",
                borderRadius: "999px",
                backgroundColor: entry.color,
              }}
            ></div>
            <Typography>{entry.value}</Typography>
          </span>
        ))}
      </div>
    );
  };

  if (loading) {
    return <Loader />;
  }

  const chartHeight = 130 + data?.length * 80;

  return (
    <div>
      <Box
        mt={10}
        sx={{
          backgroundColor: "white",
          borderRadius: "10px",
          boxShadow: "0px 4px 11px #26338B14;",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#E9F1FC",
            border: "1px solid #0E0E0E1A",
            borderRadius: "10px 10px 0px 0px",
          }}
        >
          <Box p={2.25}>
            <Typography variant="h5">Total Updates by Season</Typography>
          </Box>
        </Box>
        {data?.length == 0 ? (
          <Box sx={{ marginTop: "30px" }}>
            <EmptyScreen image={icons?.Growth} marginTop={false} />
          </Box>
        ) : (
          <ResponsiveContainer width={"100%"} height={chartHeight}>
            <BarChart
              layout="vertical"
              data={data}
              margin={{
                top: 45,
                right: 80,
                left: 100,
                bottom: 5,
              }}
            >
              <CartesianGrid
                stroke="#f5f5f5"
                vertical={true}
                horizontal={false}
              />
              <XAxis
                type="number"
                tick
                tickLine={false}
                axisLine={false}
                tickCount={13}
                style={{
                  fontSize: "12px",
                  fontFamily: "Inter_regular",
                  overflowY: "scroll",
                }}
              />
              <YAxis
                dataKey="name"
                type="category"
                axisLine={false}
                tickLine={false}
                tick={<CustomTick />}
                width={150}
                style={{ overflow: "scroll" }}
              />
              <Tooltip
                cursor={{ fill: "transparent" }}
                shared={false}
                content={<CustomTooltip />}
              />
              <Legend content={renderLegend} iconType={"circle"} />
              <Bar
                dataKey="Total Updates done"
                radius={[0, 10, 10, 0]}
                barSize={12}
                fill="#9041B9"
              />
            </BarChart>
          </ResponsiveContainer>
        )}

        {/* )} */}
      </Box>
    </div>
  );
};

export default UpdatesBySeason;
