//@ts-nocheck
import { Box, styled, Typography } from "@mui/material";

export default function DividerHeading({ title }: any) {
  return (
    <StyledContainer title={title}>
      <Typography sx={{ fontSize: "18px", fontFamily: "Inter_semibold" }}>
        {title}
      </Typography>
    </StyledContainer>
  );
}

const StyledContainer = styled(Box)(({ theme, title }) => ({
  margin: "20px 0",
  "&::after": {
    borderRadius: "2px",
    content: '""',
    display: "block",
    marginTop: "5px",
    width: `${title?.length - 2}rem`,
    height: "4px",
    backgroundImage: "linear-gradient(270deg, #014A06 0%, #73FCA8 100%);",
    bottom: "10px",
  },
}));
