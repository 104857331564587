import FileDownloadIcon from "@mui/icons-material/FileDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { http } from "../../../api/http";
import { icons } from "../../../assets";
import { DeleteButton } from "../../../components/IconButtons";
import Loader from "../../../components/Loader";
import { ImageModal } from "../../../components/UploadModal/ImageModal";
import EmptyScreen from "../../KPIManagement/EmptyScreen";

const KPIDetails = ({ kpiUpdates, isLoading, kpi }: any) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [imgUrl, setImgUrl] = useState();
  const queryClient = useQueryClient();

  const handleViewClick = (img: any) => {
    setImgUrl(img);
    setModalOpen(true);
  };

  const handleDelete = (id: any) => {
    http.delete(`/kpi/delete/form-response/${id}`).then(() => {
      queryClient.invalidateQueries("kpiUpdatesList");
    });
  };

  return (
    <div>
      {modalOpen && <ImageModal setOpen={setModalOpen} img={imgUrl} />}
      <Box
        sx={{
          marginTop: "18px",
          padding: "20px",
          backgroundColor: "#fbf9f2",
          borderRadius: " 10px 10px 0px 0px;",
          display: "flex",
          alignItems: "center",
        }}
        gap={"50px"}
      >
        <Box>
          <Typography
            variant="h6"
            sx={{
              fontSize: "16px",
              opacity: "0.5",
            }}
          >
            KPI Title
          </Typography>
          <Typography
            sx={{
              fontFamily: "Inter_semibold",
              fontSize: "16px",
            }}
          >
            {kpi?.title}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="h6"
            sx={{
              fontSize: "16px",
              opacity: "0.5",
            }}
          >
            KPI Updates
          </Typography>
          <Typography
            sx={{
              fontFamily: "Inter_semibold",
              fontSize: "16px",
            }}
          >
            {kpiUpdates?.length ?? 0}
          </Typography>
        </Box>
      </Box>
      <Box
        overflow="auto"
        maxHeight="500px"
        className="scrollBox"
        sx={{
          border: "1px solid rgba(34, 34, 34, 0.1)",
          borderRadius: "0px 0px 5px 5px",
        }}
      >
        {isLoading ? (
          <Loader />
        ) : kpiUpdates?.length == 0 ? (
          <center>
            <EmptyScreen marginTop={false} image={icons.Growth} />
          </center>
        ) : (
          kpiUpdates?.map((item: any, index: any) => {
            return (
              <Box
                sx={{
                  borderBottom:
                    index == kpiUpdates?.length - 1 ? "" : "1px solid #EFEFEF",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  {item?.data?.map((obj: any, ind: any) => {
                    return (
                      <Box p={"20px"} pb={"0px"}>
                        <Box>
                          <Typography
                            sx={{
                              fontFamily: "Inter_light",
                              fontSize: "12px",
                              opacity: "0.7",
                            }}
                          >
                            {obj?.label}
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: "Inter_regular",
                              fontSize: "16px",
                            }}
                          >
                            {obj?.type != "FILE_UPLOAD" && obj?.value}
                          </Typography>
                        </Box>
                        {obj?.type == "FILE_UPLOAD" && (
                          <>
                            <Box>
                              <Box
                                sx={{
                                  border: "1px solid rgba(34, 34, 34, 0.1)",
                                  padding: "15px",
                                  borderRadius: "5px 5px 0px 0px",
                                  width: "290px",
                                  // marginTop: "23px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                {obj?.subType == "IMAGE" && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    gap={"10px"}
                                  >
                                    <img
                                      src={icons?.IMG}
                                      style={{ width: "32px", height: "32px" }}
                                    />
                                    <Typography variant="h6">Image</Typography>
                                  </Box>
                                )}
                                {obj?.subType == "PDF" && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    gap={"10px"}
                                  >
                                    <img
                                      src={icons?.PDF}
                                      style={{ width: "32px", height: "32px" }}
                                    />
                                    <Typography variant="h6">PDF</Typography>
                                  </Box>
                                )}
                                {obj?.subType == "MIXED" && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    gap={"10px"}
                                  >
                                    <img
                                      src={icons?.Excel}
                                      style={{ width: "32px", height: "32px" }}
                                    />
                                    <Typography variant="h6">Excel</Typography>
                                  </Box>
                                )}
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "stretch",
                                  }}
                                  gap={"4px"}
                                >
                                  <a
                                    href={obj?.value}
                                    download="file"
                                    target="_blank"
                                  >
                                    <FileDownloadIcon
                                      sx={{
                                        color: "#7f829c",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </a>
                                  {obj?.subType == "IMAGE" && (
                                    <VisibilityIcon
                                      onClick={() =>
                                        handleViewClick(obj?.value)
                                      }
                                      sx={{
                                        color: "#7f829c",
                                        cursor: "pointer",
                                      }}
                                    />
                                  )}
                                </Box>
                              </Box>
                              {obj?.subType == "IMAGE" && (
                                <Box
                                  sx={{
                                    width: "322px",
                                    borderRadius: "0px 0px 5px 5px",
                                  }}
                                >
                                  <img
                                    src={obj?.value}
                                    style={{ width: "100%", height: "auto" }}
                                  />
                                </Box>
                              )}
                            </Box>
                          </>
                        )}
                      </Box>
                    );
                  })}
                  <Box mt={"45px"} ml={"20px"} mb={"20px"}>
                    <Typography
                      sx={{
                        fontFamily: "Inter_light",
                        fontSize: "12px",
                        opacity: "0.7",
                      }}
                    >
                      Last Updated Time
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Inter_regular",
                        fontSize: "16px",
                      }}
                    >
                      {moment(item?.updatedAt).format("DD-MM-YYYY, hh:mm A")}
                    </Typography>
                  </Box>
                </Box>
                <DeleteButton
                  sx={{ margin: "20px" }}
                  onClick={() => handleDelete(item?._id)}
                />
              </Box>
            );
          })
        )}
      </Box>
    </div>
  );
};

export default KPIDetails;
