//@ts-nocheck
import { Box, Modal } from "@mui/material";
import "./UploadModal.css";

export const ImageModal = ({ setOpen, img }: any) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 650,
    backgroundImage: `url(${img})`,
    bgcolor: "background.paper",
    borderRadius: "10px",
    outline: 0,
  };

  const handleClose = () => setOpen(false);
  return (
    <Modal
      // eslint-disable-next-line no-restricted-globals
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{/* <img src={img} /> */}</Box>
    </Modal>
  );
};
