import { Box, Typography } from "@mui/material";
import React from "react";
import { icons } from "../../assets";
import BarChartCard from "../../components/BarChartCard";
import Loader from "../../components/Loader";
import EmptyScreen from "../KPIManagement/EmptyScreen";

const UpdatesBySection = ({ sections, loading }: any) => {
  const barData = sections?.map((item: any) => {
    return {
      name: item?.name,
      "Total Updates done": item?.count,
    };
  });

  const barNamesAndColors = [
    {
      label: "Total Updates done",
      color: "#333761",
    },
  ];
  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <Box
        mt={10}
        sx={{
          backgroundColor: "white",
          borderRadius: "10px",
          boxShadow: "0px 4px 11px #26338B14;",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#E9F1FC",
            border: "1px solid #0E0E0E1A",
            borderRadius: "10px 10px 0px 0px",
          }}
        >
          <Box p={2.25}>
            <Typography variant="h5">Total Updates by Section</Typography>
          </Box>
        </Box>
        {/* {barData?.length === 0 ? (
        <Box
          height={400}
          display=" flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="subtitle2">
            No Incentive Earned For This Progarm
          </Typography>
        </Box>
      ) : barData === undefined ? (
        <Box
          height={400}
          display=" flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="subtitle2">Please Select a Program</Typography>
        </Box>
      ) : ( */}
        {barData?.length == 0 ? (
          <Box sx={{ marginTop: "30px" }}>
            <EmptyScreen image={icons?.Growth} marginTop={false} />
          </Box>
        ) : (
          <BarChartCard data={barData} barNamesAndColors={barNamesAndColors} />
        )}
        {/* )} */}
      </Box>
    </div>
  );
};

export default UpdatesBySection;
