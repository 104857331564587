import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  IconButton,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";

import moment from "moment";
import { SetStateAction, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { http } from "../../api/http";
import { icons } from "../../assets";
import { ViewButton } from "../../components/IconButtons/IconButtons";
import Loader from "../../components/Loader";
import Table from "../../components/TableComponent";
import EmptyScreen from "./EmptyScreen";

const KPIManagement = () => {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [formData, setFormData] = useState([]);
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const [modalOpen, setModalOpen] = useState(false);

  const toggleDrawer = (newOpen: any) => {
    setOpen(newOpen);
  };

  const handleEditClick = (row: any) => {
    setFormData(row);
    toggleDrawer(true);
  };
  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  const buttonSX = {
    "&:hover": {
      borderColor: "#24272C",
      color: "#24272C",
      background: "none",
    },
    fontSize: "15px",
    fontFamily: "Inter_semibold",
    borderRadius: "10px",
    borderColor: "#24272C",
    color: "#24272C",
  };

  const fetchAssets = async (search: string, page: number) => {
    const res = await http.get(
      `/roles?search=${search}&limit=10&offset=${
        page > 0 ? (page - 1) * 10 : page * 10
      }`
    );
    setTableData(res?.data?.data);
    setCount(res?.data?.count);
  };

  const { data, isLoading } = useQuery(["KPIRoles", search, page], () =>
    fetchAssets(search, page)
  );

  const handleDelete = (id: any) => {
    http
      .delete(`/roles/${id}`)
      .then((res) => {
        queryClient.invalidateQueries("KPIRoles");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const handleViewClick = (row: any) => {
    localStorage.setItem("roleId", row?._id);
    localStorage.setItem("roleName", row?.name);
    navigate("/kpi-management/details");
  };

  const columns = [
    {
      title: "Role",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Number of KPIs added",
      dataIndex: "noOfKpi",
      key: "noOfKpi",
    },
    {
      title: "Total Score",
      dataIndex: "marks",
      key: "marks",
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (rowData: any) =>
    //     rowData == "Published" ? (
    //       <Box
    //         sx={{
    //           backgroundColor: "#EEFAE8",
    //           padding: "6px 7px",
    //           borderRadius: "5px",
    //           width: "122px",
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           color: "#57CA22",
    //         }}
    //       >
    //         Published
    //       </Box>
    //     ) : (
    //       <Box
    //         sx={{
    //           backgroundColor: "#F4EAFF",
    //           padding: "6px 7px",
    //           borderRadius: "5px",
    //           width: "122px",
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           color: "#9234FF",
    //         }}
    //       >
    //         Drafted
    //       </Box>
    //     ),
    // },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (row: any) => {
        return moment(row).format("YYYY-MMM-DD");
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_: any, row: any) => {
        return <ViewButton onClick={() => handleViewClick(row)} />;
      },
    },
  ];

  function handlePagination(e: any, v: SetStateAction<number>) {
    setPage(v);
  }

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography variant="subtitle1">Employees</Typography>
          <form onSubmit={() => {}}>
            <TextField
              label="Search by Role"
              sx={{ width: "450px", padding: "0px" }}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => {}}>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              value={search}
              onChange={(e) => setSearch(e?.target?.value)}
            />
          </form>
        </Box>
      </Box>
      {isLoading || loading || !tableData ? (
        <Loader />
      ) : tableData?.length == 0 ? (
        <center>
          <EmptyScreen image={icons.EmptyRole} />
        </center>
      ) : (
        <Box sx={{ marginTop: "20px" }}>
          <Table
            key={"KPIManagement"}
            dataSource={tableData}
            rowKey={"id"}
            columns={columns}
            loading={isLoading}
          />
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Pagination
              count={count > 10 ? Math.ceil(count / 10) : 1}
              variant="outlined"
              shape="rounded"
              onChange={handlePagination}
              page={page}
            />{" "}
          </Box>
        </Box>
      )}
    </div>
  );
};

export default KPIManagement;
