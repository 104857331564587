//@ts-nocheck
import { Box, Drawer, Pagination, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";

import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { http } from "../../api/http";
import { icons } from "../../assets";
import AddItemButton from "../../components/AddItemButton";
import Breadcrumbs from "../../components/Breadcrumbs";
import DividerHeading from "../../components/DividerHeading";
import {
  StyledDrawerContent,
  StyledDrawerHeader,
} from "../../components/DrawerTemplate";
import EmptyScreen from "../../components/EmptyScreen";
import { DeleteButton, EditButton } from "../../components/IconButtons";
import Loader from "../../components/Loader";
import PageHeader from "../../components/PageHeader";
import Table from "../../components/TableComponent";
import Form from "./form";

const KPIDetails = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const ID = localStorage.getItem("roleId");
  const roleName = localStorage.getItem("roleName");

  const [formData, setFormData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [roleDetails, setRoleDetails] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const queryClient = useQueryClient();

  const buttonSX = {
    "&:hover": {
      borderColor: "#24272C",
      color: "#5EDB8A",
      background: "none",
    },
    color: "#8E8E8E",
    cursor: "pointer",
  };

  const toggleDrawer = (newOpen: any) => {
    setOpen(newOpen);
  };

  useEffect(() => {
    http.get(`/roles/${ID}`).then((res) => {
      setRoleDetails(res?.data);
    });
  }, []);

  const fetchAssets = async (page: number) => {
    const res = await http.get(
      `/kpi?roleId=${ID}&limit=10&offset=${
        page > 0 ? (page - 1) * 10 : page * 10
      }&platform=WEB`
    );

    setTableData(res?.data?.data);
    setCount(res?.data?.count);
  };

  const { data, isLoading } = useQuery(["KPIs", page], () => fetchAssets(page));

  const handleDelete = (id: any) => {
    http
      .delete(`/kpi/${id}`)
      .then((res) => {
        queryClient.invalidateQueries("KPIs");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const handleEditClick = (row: any) => {
    setFormData(row);
    toggleDrawer(true);
  };

  const handleAddClick = (id: any) => {
    localStorage.setItem("kpiId", id);
    navigate(`/kpi-management/form-builder`);
  };

  function handlePagination(e, v) {
    setPage(v);
  }

  const columns = [
    {
      title: "KPI Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Points Allocated",
      dataIndex: "pointsAllocated",
      key: "pointsAllocated",
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
      key: "frequency",
    },
    {
      title: "Grading Criteria",
      dataIndex: "calculationFrequency",
      key: "calculationFrequency",
    },
    {
      title: "Buffer Days",
      dataIndex: "bufferDays",
      key: "bufferDays",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (rowData) =>
        rowData == "Drafted" ? (
          <Box
            sx={{
              backgroundColor: "#FFF8E5",
              padding: "8px 0px",
              borderRadius: "10px",
              width: "122px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#FFB700",
            }}
          >
            Drafted
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: "#EBF3EF",
              padding: "8px 0px",
              borderRadius: "10px",
              width: "122px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#3A8B63",
            }}
          >
            Published
          </Box>
        ),
    },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_: any, row: any) => {
        return (
          <Box sx={{ display: "flex" }} gap={"15px"}>
            <EditButton
              onClick={() => {
                handleEditClick(row);
              }}
            />
            <AddIcon sx={buttonSX} onClick={() => handleAddClick(row?._id)} />
            <DeleteButton onClick={() => handleDelete(row?._id)} />
          </Box>
        );
      },
    },
  ];
  return (
    <div>
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: "KPI Management", to: "/kpi-management" },
              {
                name: roleName,
                to: null,
              },
            ]}
          />
        }
      />
      <Box
        sx={{
          boxShadow: "0px 4px 16px #313B7E14",
          padding: "36px",
          display: "flex",
          alignItems: "center",
          borderRadius: "10px",
        }}
        gap={"120px"}
      >
        <Typography variant="h5" sx={{ fontSize: "16px" }}>
          {roleDetails?.name}
        </Typography>
        <Box>
          <Typography variant="subtitle2" sx={{ opacity: "0.7" }}>
            Number of KPIs added
          </Typography>
          <Typography variant="h5" sx={{ fontSize: "16px" }}>
            {roleDetails?.noOfKpi}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" sx={{ opacity: "0.7" }}>
            Points Allocated
          </Typography>
          <Typography variant="h5" sx={{ fontSize: "16px" }}>
            {roleDetails?.marks}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <DividerHeading title={"Key Performance Indicators"} />
        <AddItemButton
          title={"Add New KPI"}
          func={() => {
            setFormData(null);
            toggleDrawer(true);
          }}
        />
      </Box>
      {isLoading || !tableData ? (
        <Loader />
      ) : tableData?.length == 0 ? (
        <center style={{ marginTop: "-10px" }}>
          <EmptyScreen title={"KPI"} word={"KPIs"} image={icons.Growth} />
        </center>
      ) : (
        <Box sx={{ marginTop: "20px" }}>
          <Table
            key={"KPIs"}
            dataSource={tableData}
            rowKey={"id"}
            columns={columns}
            loading={isLoading}
          />
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Pagination
              count={count > 10 ? Math.ceil(count / 10) : 1}
              variant="outlined"
              shape="rounded"
              onChange={handlePagination}
              page={page}
            />{" "}
          </Box>
        </Box>
      )}
      <Drawer
        anchor="right"
        open={open}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <StyledDrawerHeader>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Inter_semibold",
              color: "black",
            }}
          >
            {formData ? "Edit KPI parameters" : "Add KPI parameters"}
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontFamily: "Inter_semibold",
              color: "black",
              opacity: "0.5",
              cursor: "pointer",
            }}
            onClick={() => toggleDrawer(false)}
          >
            Cancel
          </Typography>
        </StyledDrawerHeader>
        <StyledDrawerContent>
          <Box sx={{ width: "550px" }}>
            <Form data={formData} toggleDrawer={toggleDrawer} />
          </Box>
        </StyledDrawerContent>
      </Drawer>
    </div>
  );
};

export default KPIDetails;
