import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { http } from "../../api/http";
import { icons } from "../../assets";
import Loader from "../../components/Loader";
import Table from "../../components/TableComponent";
import EmptyScreen from "../KPIManagement/EmptyScreen";

const Reports = () => {
  const [FYID, setFYID] = useState<any>(localStorage?.getItem("financialYear"));
  const [FYs, setFYs] = useState([]);

  const { data, isLoading } = useQuery(["reports", FYID], () =>
    http.get(`dashboard/employee-report?financialYearId=${FYID}`)
  );

  const handleExport = () => {
    http
      .get(`dashboard/employee-report/export?financialYearId=${FYID}`)
      .then((res) => {
        const arr = new Uint8Array(res.data?.data);
        const blob = new Blob([arr], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const file = window.URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.href = file;
        link.download = "reports.xlsx";
        link.click();
        toast.success("Exported successfully");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    http
      .get("/finance")
      .then((res) => {
        setFYs(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }, []);

  const navigate = useNavigate();

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Employee Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Number of Updates",
      dataIndex: "updates",
      key: "updates",
    },
    {
      title: "Points Allocated",
      dataIndex: "totalPoints",
      key: "totalPoints",
    },
    {
      title: "Points Awarded",
      dataIndex: "awardedPoints",
      key: "awardedPoints",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
      render: (_row: any) => {
        return _row + "%";
      },
    },
  ];

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography variant="subtitle1">Reports</Typography>
          <FormControl
            sx={{
              width: "280px",
            }}
          >
            <InputLabel id="frequency">Select Financial Year</InputLabel>
            <Select
              labelId="financialYear"
              id="financialYear"
              label="Select Financial Year"
              sx={{
                borderRadius: "5px",
              }}
              value={FYID}
              onChange={(e) => {
                setFYID(e?.target?.value);
              }}
            >
              {FYs?.map((item: any) => {
                return <MenuItem value={item?._id}>{item?.title}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={2} mr={1}>
        <Button
          variant="contained"
          sx={{ height: "40px" }}
          onClick={handleExport}
        >
          <Typography sx={{ color: "white" }}>Export</Typography>
        </Button>
      </Box>
      {isLoading ? (
        <Loader />
      ) : data?.data?.length == 0 ? (
        <center>
          <EmptyScreen image={icons.EmptyRole} />
        </center>
      ) : (
        <Box sx={{ marginTop: "20px" }}>
          <Table
            key={"KPIManagement"}
            dataSource={data?.data}
            rowKey={"id"}
            columns={columns}
            loading={isLoading}
          />
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          ></Box>
        </Box>
      )}
    </div>
  );
};

export default Reports;
