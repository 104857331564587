import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmDialogProvider from "../context/ConfirmDialog";
import "./App.css";
import RoutesContainer from "./RoutesContainer";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ConfirmDialogProvider>
          <QueryClientProvider client={queryClient}>
            <RoutesContainer />
          </QueryClientProvider>
        </ConfirmDialogProvider>
      </LocalizationProvider>
      <ToastContainer />
    </div>
  );
};

export default App;
