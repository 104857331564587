import { v4 as uuidv4 } from "uuid";
import { icons } from "../../../assets";
import { FormBuildertypes } from "./renderFieldsComponent";

const availableFields = [
  {
    id: uuidv4(),
    icon: icons.SingleLine,
    label: "Input Value",
    type: FormBuildertypes.INPUT,
  },

  {
    id: uuidv4(),
    icon: icons.CheckBox,
    label: "Status Picklist",
    type: FormBuildertypes.CHECKBOX,
  },

  {
    id: uuidv4(),
    icon: icons.FileUpload,
    label: "Upload File",
    type: FormBuildertypes.FILE_UPLOAD,
  },
];

export default availableFields;
