import { Box, Typography } from "@mui/material";
import React from "react";

const EmptyScreen = ({ image, marginTop = true }: any) => {
  return (
    <div>
      <Box
        p={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: marginTop ? "320px" : "",
        }}
      >
        <img src={image} width="60px" height="60px" />
        <Typography
          variant="h4"
          sx={{ fontSize: "17px", marginTop: "10px" }}
        >{`Please select a Financial Year to view the Overall Score`}</Typography>
      </Box>
    </div>
  );
};

export default EmptyScreen;
