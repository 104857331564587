//@ts-nocheck
import Block from "./images/dashboard.png";
import LoginPic from "./images/cropsBackground.png";
import Logo from "./images/tssoca.png";
import Kpi from "./images/kpi.png";
import Employee from "./images/employee.png";
import Notifications from "./images/notifications.png";
import Settings from "./images/settings.png";
import Back from "./images/back.png";
import Config from "./images/config.png";
import CropsConfig from "./images/hand-holding-seeding.png";
import OrgConfig from "./images/orgConfig.png";
import EmpConfig from "./images/empConfig.png";
import FinConfig from "./images/finConfig.png";
import SeasonConfig from "./images/clouds.png";
import Section from "./images/section.png";
import Location from "./images/location.png";
import Office from "./images/bank.png";
import Add from "./images/add.png";
import EmptySection from "./images/EmptySections.png";
import EmptyLocation from "./images/EmptyLocation.png";
import EmptyOffice from "./images/EmptyOffice.png";
import EmptyRole from "./images/EmptyRole.png";
import Excel from "./images/excel.png";
import Plant from "./images/plant.png";
import CutOffDate from "./images/calendar-lines-pen.png";
import EmptyCrop from "./images/cropType.png";
import Growth from "./images/growth.png";
import UserIcon from "./images/userIcon.png";
import Exit from "./images/exit.png";
import EmptyEmployee from "./images/EmptyEmployee.png";
import Download from "./images/cloud-download.png";
import More from "./images/more.png";
import AddPic from "./images/AddPic.png";
import ClientFormCard from "./images/clientFormCard.png";
import SingleLine from "./images/singleLine.svg";
import CheckBox from "./images/checkBox.svg";
import FileUpload from "./images/fileUpload.svg";
import PDF from "./images/pdf.png";
import IMG from "./images/img.png";
import PIC from "./images/pic.png";
import Groups from "./images/Groups.png";
import RoleMetrics from "./images/RolesMetrics.png";
import KPIUpdates from "./images/KPIUpdates.png";
import ArrowUp from "./images/arrowUp.png";
import ArrowDown from "./images/downArrow.png";
import EmptyFY from "./images/EmptyFY.png";
import EmptySeasons from "./images/EmptySeasons.png";
import Reports from "./images/reports.png";

export const icons = {
  Reports,
  Block,
  Kpi,
  Employee,
  Notifications,
  Settings,
  Back,
  Config,
  CropsConfig,
  OrgConfig,
  EmpConfig,
  FinConfig,
  SeasonConfig,
  Section,
  Location,
  Office,
  Add,
  EmptySection,
  EmptyLocation,
  EmptyOffice,
  EmptyRole,
  Excel,
  Plant,
  CutOffDate,
  EmptyCrop,
  Growth,
  UserIcon,
  Exit,
  EmptyEmployee,
  Download,
  More,
  AddPic,
  ClientFormCard,
  SingleLine,
  CheckBox,
  FileUpload,
  PDF,
  IMG,
  Groups,
  RoleMetrics,
  KPIUpdates,
  ArrowUp,
  ArrowDown,
  EmptySeasons,
  EmptyFY,
};

export { LoginPic, Logo, PIC };
