import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Logo, icons } from "../../assets";
import { StyledBadge } from "../styles";

const Appbar = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickLogout = () => {
    setAnchorEl(null);
    localStorage.clear();
    window.location.href = "/login";
  };

  const handleClickLogo = () => {
    navigate("/dashboard");
  };

  return (
    <>
      <AppBar color="inherit" position="fixed">
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ cursor: "pointer" }} onClick={handleClickLogo}>
            <img
              src={Logo}
              alt="logo"
              style={{ width: "240px", padding: "4px" }}
            />
          </Box>
          <Box
            mr={2}
            display="flex"
            width="140px"
            justifyContent="space-between"
          >
            <IconButton
              onClick={() =>
                navigate(
                  "/settings/tssoca-configuration/financial-configuration"
                )
              }
            >
              <img src={icons.Settings} />
            </IconButton>
            <IconButton>
              <img src={icons.Notifications} alt="" />
            </IconButton>
            <Box sx={{ cursor: "pointer" }}>
              <div>
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  <Avatar
                    onClick={handleMenu}
                    alt={""}
                    src={""}
                    sx={{ bgcolor: "black" }}
                  />
                </StyledBadge>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                >
                  <MenuItem onClick={handleClickLogout}>
                    <Box padding="5px" display="flex" alignItems="center">
                      <img src={icons.Exit} alt="" />
                      <Typography ml={1} variant="subtitle2">
                        Logout
                      </Typography>
                    </Box>
                  </MenuItem>
                </Menu>
              </div>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Appbar;
