//@ts-nocheck
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { http } from "../../api/http";

const Form = ({ data, toggleDrawer }: any) => {
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState("");
  const seasonsArray = data?.seasons?.map((obj, ind) => {
    return obj?.name;
  });
  const [seasons, setSeasons] = useState([]);
  const [seasonIds, setSeasonIds] = useState(seasonsArray ? seasonsArray : []);

  useEffect(() => {
    http.get(`/crops/types`).then((res) => {
      setSeasons(res?.data);
    });
  }, []);
  useEffect(() => {
    data && setEdit(true);
    data && setId(data?._id);
  }, []);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      name: data?.name,
    },
  });

  const queryClient = useQueryClient();

  const onTagsChange = (event, values) => {
    setSeasonIds(values);
  };

  const onSubmit = async (data: any) => {
    if (edit) {
      const formData = {
        ...data,
        seasons: seasonIds,
      };
      http
        .patch(`/crops/${id}`, formData)
        .then((res) => {
          toast.success("The details have been updated");
          queryClient.invalidateQueries("cropConf");
          toggleDrawer(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } else {
      const formData = {
        ...data,
        seasons: seasonIds,
      };
      http
        .post(`/crops`, formData)
        .then((res) => {
          toast.success("The record has been created");
          queryClient.invalidateQueries("cropConf");
          toggleDrawer(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  return (
    <div>
      {" "}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            type={"text"}
            {...register("name", {
              required: true,
              pattern: { value: /^[ A-Za-z0-9()[]+.-*/ % /]*$/ },
            })}
            label={"Crop Type"}
            required={true}
            fullWidth={true}
            variant={"standard"}
            sx={{ marginTop: "24px" }}
          />
          {errors?.name?.type == "required" && (
            <Typography sx={{ color: "red" }}>
              This field is required
            </Typography>
          )}
          {errors?.name?.type == "pattern" && (
            <Typography sx={{ color: "red" }}>Invalid Name</Typography>
          )}
          <Autocomplete
            multiple
            id="tags-outlined"
            options={seasons}
            value={seasonIds}
            getOptionLabel={(option) => option}
            filterSelectedOptions
            onChange={onTagsChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Seasons"
                variant="standard"
                sx={{ marginTop: "24px" }}
              />
            )}
          />
          <Button
            type="submit"
            variant="contained"
            sx={{ marginTop: "24px" }}
            disabled={seasonIds?.length == 0 ? true : false}
          >
            Submit
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default Form;
