import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  ClickAwayListener,
  Collapse,
  IconButton,
  ListItem,
  Zoom,
} from "@mui/material";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { icons } from "../../../assets";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { firstItems, menuItems } from "./menu";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function SideNav() {
  const location = useLocation();
  const navigate = useNavigate();
  const [firstOpen, setFirstOpen] = useState<boolean>(true);
  const [secondOpen, setSecondOpen] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const [state, setState] = useState({
    title: "Reports",
    path: "/reports",
  });

  const handleMenuClick = (title: any) => {
    title == "TSSOCA Configuration"
      ? setFirstOpen(!firstOpen)
      : setSecondOpen(!secondOpen);
  };

  return (
    <Box
      sx={{
        width: "350px",
        height: "100vh",
        position: "fixed",
        backgroundColor: "black",
        color: "white",
        // padding: "15px",
        overflow: "hidden",
      }}
    >
      <Box mt={8}>
        <List>
          <Box
            sx={{
              width: "100%",
              borderBottom: "0.5px solid grey",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                // height: "80px",
                padding: "8px 5px",
                marginTop: "12px",
              }}
            >
              <ListItemIcon>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/")}
                >
                  <img src={icons.Back} alt="" width={16} height={16} />
                </Box>
              </ListItemIcon>
              <ListItemText
                color="white"
                primary={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "2px",
                    }}
                  >
                    <Typography color="#FFFFFF" fontSize="14.5px">
                      Settings
                    </Typography>
                  </Box>
                }
              />
            </Box>
          </Box>

          {menuItems.map((item, index) => (
            <Link
              to={item.path}
              key={index}
              style={{ textDecoration: "none", color: "initial" }}
            >
              <ListItemButton
                selected={location.pathname.startsWith(item.path)}
                onClick={() => handleMenuClick(item?.title)}
                sx={{
                  "&:hover": {
                    background: "#1D1D1D",
                    borderRadius: "5px",
                  },
                  "&.Mui-selected": {
                    background: "#1D1D1D",
                    borderRadius: "5px",
                  },
                  "&.Mui-selected:hover": {
                    background: "#1D1D1D",
                    borderRadius: "5px",
                  },
                  display: "flex",
                  alignItems: "center",
                  // height: "80px",
                  padding: "8px 15px 8px 15px",
                  margin: "0px 15px",
                  marginTop: "13px",
                }}
              >
                <ListItemIcon>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={item.icon} alt="" width={18} height={18} />
                  </Box>
                </ListItemIcon>
                <ListItemText
                  color="white"
                  primary={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "2px",
                      }}
                    >
                      <Typography color="#FFFFFF" fontSize="14.5px">
                        {item?.title}
                      </Typography>
                    </Box>
                  }
                />
                {item?.title == "TSSOCA Configuration" &&
                  (firstOpen ? (
                    <ExpandLess sx={{ color: "white" }} />
                  ) : (
                    <ExpandMore sx={{ color: "white" }} />
                  ))}
              </ListItemButton>
              {item?.title == "TSSOCA Configuration" && (
                <Collapse
                  in={
                    item?.title == "TSSOCA Configuration"
                      ? firstOpen
                      : secondOpen
                  }
                  timeout="auto"
                  unmountOnExit
                >
                  <List
                    component="div"
                    disablePadding
                    sx={{ marginLeft: "20px", marginTop: "5px" }}
                  >
                    {firstItems?.map((obj, ind) => {
                      return (
                        <Link
                          to={obj.path}
                          key={ind}
                          style={{ textDecoration: "none", color: "initial" }}
                        >
                          <ListItemButton
                            selected={location.pathname.startsWith(obj.path)}
                            sx={{
                              "&:hover": {
                                background: "#1D1D1D",
                                borderRadius: "5px",
                              },
                              "&.Mui-selected": {
                                background: "#1D1D1D",
                                borderRadius: "5px",
                              },
                              "&.Mui-selected:hover": {
                                background: "#1D1D1D",
                                borderRadius: "5px",
                              },
                              display: "flex",
                              alignItems: "center",
                              // height: "80px",
                              padding: "5px 8px",
                              margin: "3px 15px 3px 30px",
                            }}
                          >
                            <ListItemIcon>
                              <Box
                                sx={{
                                  width: "100%",
                                  textAlign: "center",
                                }}
                              >
                                <img
                                  src={obj?.icon}
                                  alt=""
                                  width={20}
                                  height={20}
                                />
                              </Box>{" "}
                            </ListItemIcon>
                            <ListItemText
                              color="white"
                              primary={
                                <Typography
                                  variant="h6"
                                  color="#FFFFFF"
                                  fontSize="14.5px"
                                  textAlign="left"
                                >
                                  {obj?.title}
                                </Typography>
                              }
                            />{" "}
                          </ListItemButton>
                        </Link>
                      );
                    })}
                  </List>
                </Collapse>
              )}
              {/* {item?.title == "Crop types Configuration" && (
                <Collapse
                  in={
                    item?.title == "Crop types Configuration"
                      ? firstOpen
                      : secondOpen
                  }
                  timeout="auto"
                  unmountOnExit
                >
                  <List
                    component="div"
                    disablePadding
                    sx={{ marginLeft: "20px", marginTop: "5px" }}
                  >
                    {secondItems?.map((obj, ind) => {
                      return (
                        <Link
                          to={obj.path}
                          key={ind}
                          style={{ textDecoration: "none", color: "initial" }}
                        >
                          <ListItemButton
                            selected={location.pathname.startsWith(obj.path)}
                            sx={{
                              "&:hover": {
                                background: "#1D1D1D",
                                borderRadius: "5px",
                              },
                              "&.Mui-selected": {
                                background: "#1D1D1D",
                                borderRadius: "5px",
                              },
                              "&.Mui-selected:hover": {
                                background: "#1D1D1D",
                                borderRadius: "5px",
                              },
                              display: "flex",
                              alignItems: "center",
                              // height: "80px",
                              padding: "5px 8px",
                              margin: "3px 15px 3px 30px",
                            }}
                          >
                            <ListItemIcon>
                              <Box
                                sx={{
                                  width: "100%",
                                  textAlign: "center",
                                }}
                              >
                                <img
                                  src={obj?.icon}
                                  alt=""
                                  width={20}
                                  height={20}
                                />
                              </Box>{" "}
                            </ListItemIcon>
                            <ListItemText
                              color="white"
                              primary={
                                <Typography
                                  variant="h6"
                                  color="#FFFFFF"
                                  fontSize="14.5px"
                                  textAlign="left"
                                >
                                  {obj?.title}
                                </Typography>
                              }
                            />{" "}
                          </ListItemButton>
                        </Link>
                      );
                    })}
                  </List>
                </Collapse>
              )} */}
            </Link>
          ))}
          {/* <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={"/dashboard"}
                style={{ textDecoration: "none", color: "initial" }}
              >
                <ListItemButton
                  selected={location.pathname.startsWith("/dashboard")}
                  sx={{
                    "&.Mui-selected": {
                      background: "#3F3680",
                      borderRadius: "5px",
                    },
                    "&.Mui-selected:hover": {
                      background: "#3F3680",
                      borderRadius: "5px",
                    },
                    display: "flex",
                    alignItems: "center",
                    // height: "80px",
                    padding: "8px",
                    marginTop: "12px",
                    marginLeft: "30px",
                  }}
                >
                  <ListItemIcon>
                    <Box
                      sx={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      <img src={icons.Block} alt="" width={20} height={20} />
                    </Box>{" "}
                  </ListItemIcon>
                  <ListItemText
                    color="white"
                    primary={
                      <Typography
                        variant="h6"
                        color="#FFFFFF"
                        fontSize="14.5px"
                        textAlign="left"
                      >
                        {"Dashboard"}
                      </Typography>
                    }
                  />{" "}
                </ListItemButton>
              </Link>
              <Link
                to={"/dashboard-payments"}
                style={{ textDecoration: "none", color: "initial" }}
              >
                <ListItemButton
                  selected={location.pathname.startsWith("/dashboard-payments")}
                  sx={{
                    "&.Mui-selected": {
                      background: "#3F3680",
                      borderRadius: "5px",
                    },
                    "&.Mui-selected:hover": {
                      background: "#3F3680",
                      borderRadius: "5px",
                    },
                    display: "flex",
                    alignItems: "center",
                    // height: "80px",
                    padding: "8px",
                    marginTop: "12px",
                    marginLeft: "30px",
                  }}
                >
                  <ListItemIcon>
                    <Box
                      sx={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      <img src={icons.Block} alt="" width={20} height={20} />
                    </Box>{" "}
                  </ListItemIcon>
                  <ListItemText
                    color="white"
                    primary={
                      <Typography
                        variant="h6"
                        color="#FFFFFF"
                        fontSize="14.5px"
                        textAlign="left"
                      >
                        {"Payments Dashboard"}
                      </Typography>
                    }
                  />{" "}
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        
          {/* <SidenavToggleItem state={state} CustomTooltip={CustomTooltip} />
          <CustomTooltip
            title={
              <Typography variant="subtitle2" sx={{ color: "white" }}>
                more
              </Typography>
            }
            TransitionComponent={Zoom}
            arrow
            placement="right"
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <IconButton onClick={handleClick("right")}>
                  <MoreHorizIcon sx={{ color: "white", fontSize: "30px" }} />
                </IconButton>
              </ClickAwayListener>
            </Box>
          </CustomTooltip> */}
        </List>
      </Box>
    </Box>
  );
}
export default SideNav;
