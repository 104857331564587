//@ts-nocheck
import { Box, Button, FormControl, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { http } from "../../api/http";

const Form = ({ data, toggleDrawer }: any) => {
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState();
  const [startDate, setStartDate] = useState();
  useEffect(() => {
    data && setEdit(true);
    data && setId(data?._id);
  }, []);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      name: data?.name,
      noOfKpi: data?.noOfKpi,
      marks: data?.marks,
      cadreLevel: data?.cadreLevel,
    },
  });

  const queryClient = useQueryClient();

  const onSubmit = async (data: any) => {
    if (edit) {
      http
        .patch(`/roles/${id}`, data)
        .then((res) => {
          toast.success("The role has been updated");
          queryClient.invalidateQueries("rolesConf");
          toggleDrawer(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } else {
      http
        .post(`/roles`, data)
        .then((res) => {
          toast.success("The role has been created");
          queryClient.invalidateQueries("rolesConf");
          toggleDrawer(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  return (
    <div>
      {" "}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            type={"text"}
            {...register("name", {
              required: true,
              pattern: { value: /^[ A-Za-z0-9()[]+.-*/ % /]*$/ },
            })}
            label={"Role Title"}
            required={true}
            fullWidth={true}
            variant={"standard"}
            sx={{ marginTop: "24px" }}
          />
          {errors?.name?.type == "required" && (
            <Typography sx={{ color: "red" }}>
              This field is required
            </Typography>
          )}
          {errors?.name?.type == "pattern" && (
            <Typography sx={{ color: "red" }}>Invalid Name</Typography>
          )}

          <FormControl fullWidth sx={{ marginTop: "24px" }}>
            <TextField
              type={"number"}
              inputProps={{ min: 0 }}
              {...register("noOfKpi", {
                required: true,
              })}
              variant={"standard"}
              label={"Number of KPIs"}
              required={true}
            />
          </FormControl>
          {errors?.noOfKpi && (
            <Typography
              sx={{
                fontFamily: "Inter_medium",
                color: "red",
              }}
            >
              This field is required
            </Typography>
          )}
          <FormControl fullWidth sx={{ marginTop: "24px" }}>
            <TextField
              type={"number"}
              inputProps={{ min: 0 }}
              {...register("marks", {
                required: true,
              })}
              label={"Points"}
              variant={"standard"}
              required={true}
            />
          </FormControl>
          {errors?.marks && (
            <Typography
              sx={{
                fontFamily: "Inter_medium",
                color: "red",
              }}
            >
              This field is required
            </Typography>
          )}
          <FormControl fullWidth sx={{ marginTop: "24px" }}>
            <TextField
              type={"number"}
              inputProps={{ min: 0 }}
              {...register("cadreLevel", {
                required: true,
              })}
              label={"Cadre Level"}
              variant={"standard"}
              required={true}
            />
          </FormControl>
          {errors?.cadreLevel && (
            <Typography
              sx={{
                fontFamily: "Inter_medium",
                color: "red",
              }}
            >
              This field is required
            </Typography>
          )}
          <Button type="submit" variant="contained" sx={{ marginTop: "24px" }}>
            Submit
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default Form;
