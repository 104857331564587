import { Box, Typography } from "@mui/material";
import React from "react";

const EmptyPageScreen = ({ image }: any) => {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "160px",
        }}
      >
        <img src={image} width="60px" height="60px" />
        <Typography
          variant="h4"
          sx={{ fontSize: "24px" }}
        >{`No Questions added`}</Typography>
        <Typography
          variant="subtitle2"
          sx={{ opacity: "0.7", marginTop: "6px" }}
        >{`Drag and Drop question types to configure questions in the KPI`}</Typography>
      </Box>
    </div>
  );
};

export default EmptyPageScreen;
