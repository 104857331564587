import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useQuery } from "react-query";

import { http } from "../../api/http";
import { icons } from "../../assets";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import PageHeader from "../../components/PageHeader";
import EmptyScreen from "./EmptyScreen";

const EmployeeAnnualPerformance = () => {
  const [FYID, setFYID] = useState("");
  const empDetails = JSON.parse(localStorage.getItem("empDetails") || "{}");
  const name = empDetails?.name;
  const roleName = empDetails?.role;
  const roleID = empDetails?.roleID;
  const empID = empDetails?.id;
  const division = empDetails?.division;
  const updatedAt = empDetails?.updatedAt;

  const { data: FYs, isLoading: FYLoading } = useQuery(["FYs"], async () => {
    const res = await http.get("/finance");
    return res?.data?.data;
  });

  const fetchAssets = async (FYID: any) => {
    const res = await http.get(
      `/dashboard/annual-report?financialYearId=${FYID}&userId=${empID}&roleId=${roleID}`
    );
    return res?.data;
  };

  const { data, isLoading } = useQuery(
    ["overallScore", FYID],
    () => fetchAssets(FYID),
    {
      enabled: !!FYID,
    }
  );

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <PageHeader
          title={
            <Breadcrumbs
              links={[
                {
                  name: "Employee KPI Management",
                  to: "/employee-kpi-management",
                },
                {
                  name: "Roles",
                  to: "/employee-kpi-management/roles",
                },
                {
                  name: "Employee Annual Performance",
                  to: null,
                },
              ]}
            />
          }
        />
        <FormControl
          sx={{
            width: "250px",
          }}
        >
          <InputLabel id="frequency">Select Financial Year</InputLabel>
          <Select
            labelId="financialYear"
            id="financialYear"
            label="Select Financial Year"
            sx={{
              borderRadius: "5px",
            }}
            value={FYID}
            onChange={(e) => setFYID(e?.target?.value)}
          >
            {FYs?.map((item: any) => {
              return <MenuItem value={item?._id}>{item?.title}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          boxShadow: "0px 4px 16px #313B7E14",
          padding: "36px",
          display: "flex",
          alignItems: "center",
          marginTop: "15px",
          justifyContent: "space-between",
          borderRadius: "10px",
        }}
      >
        <Box>
          <Typography
            variant="subtitle2"
            sx={{ opacity: "0.5", fontSize: "13px" }}
          >
            Employee Name
          </Typography>
          <Typography variant="h5" sx={{ fontSize: "16px" }}>
            {name}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="subtitle2"
            sx={{ opacity: "0.5", fontSize: "13px" }}
          >
            Role
          </Typography>
          <Typography variant="h5" sx={{ fontSize: "16px" }}>
            {roleName}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="subtitle2"
            sx={{ opacity: "0.5", fontSize: "13px" }}
          >
            Division
          </Typography>
          <Typography variant="h5" sx={{ fontSize: "16px" }}>
            {division}
          </Typography>
        </Box>

        <Box>
          <Typography
            variant="subtitle2"
            sx={{ opacity: "0.5", fontSize: "13px" }}
          >
            Last Updated Time
          </Typography>
          <Typography variant="h5" sx={{ fontSize: "16px" }}>
            {moment(updatedAt).format("DD-MMM-YYYY, hh:mm A")}
          </Typography>
        </Box>
      </Box>
      {!data ? (
        <Box sx={{ marginTop: "30px" }}>
          <EmptyScreen image={icons?.Growth} marginTop={false} />
        </Box>
      ) : (
        <Box
          sx={{
            padding: "36px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "10px",
            backgroundColor: "#00063A",
            marginTop: "24px",
          }}
        >
          <Box>
            <Typography variant="h5" sx={{ fontSize: "16px", color: "white" }}>
              Overall Score :
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="subtitle2"
              sx={{ opacity: "0.5", fontSize: "13px", color: "white" }}
            >
              Total Marks Allocated
            </Typography>
            <Typography variant="h5" sx={{ fontSize: "16px", color: "white" }}>
              {data?.pointsAllocated}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="subtitle2"
              sx={{ opacity: "0.5", fontSize: "13px", color: "white" }}
            >
              Total Marks Obtained
            </Typography>
            <Typography variant="h5" sx={{ fontSize: "16px", color: "white" }}>
              {data?.pointsAchieved}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="subtitle2"
              sx={{ opacity: "0.5", fontSize: "13px", color: "white" }}
            >
              Total Percentage
            </Typography>
            <Typography variant="h5" sx={{ fontSize: "16px", color: "white" }}>
              {Math.round(data?.percentage * 100) / 100 + "%"}
            </Typography>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default EmployeeAnnualPerformance;
