//@ts-nocheck
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Drawer,
  IconButton,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";

import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import AddItemButton from "../../../components/AddItemButton";
import {
  StyledDrawerContent,
  StyledDrawerHeader,
} from "../../../components/DrawerTemplate";
import { EditButton } from "../../../components/IconButtons";
import { DeleteButton } from "../../../components/IconButtons/IconButtons";
import Loader from "../../../components/Loader";
import Table from "../../../components/TableComponent";
import Form from "./form";

import moment from "moment";
import { http } from "../../../api/http";
import { icons } from "../../../assets";
import EmptyScreen from "../../../components/EmptyScreen";

const Offices = () => {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [formData, setFormData] = useState([]);
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const toggleDrawer = (newOpen: any) => {
    setOpen(newOpen);
  };

  const handleEditClick = (row: any) => {
    setFormData(row);
    toggleDrawer(true);
  };
  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  const buttonSX = {
    "&:hover": {
      borderColor: "#24272C",
      color: "#24272C",
      background: "none",
    },
    fontSize: "15px",
    fontFamily: "Inter_semibold",
    borderRadius: "10px",
    borderColor: "#24272C",
    color: "#24272C",
  };

  const fetchAssets = async (search: string, page: number) => {
    const res = await http.get(
      `/office?search=${search}&limit=10&offset=${
        page > 0 ? (page - 1) * 10 : page * 10
      }`
    );
    setTableData(res?.data?.data);
    setCount(res?.data?.count);
  };

  const { data, isLoading } = useQuery(["officeConf", search, page], () =>
    fetchAssets(search, page)
  );

  const handleDelete = (id) => {
    http
      .delete(`/office/${id}`)
      .then((res) => {
        queryClient.invalidateQueries("officeConf");
      })
      .catch((err) => {
        enqueueSnackbar(err?.response?.data?.message);
      });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (row) => {
        return moment(row).format("YYYY-MMM-DD");
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_, row) => {
        return (
          <Box sx={{ display: "flex" }} gap={"18px"}>
            <EditButton
              onClick={() => {
                handleEditClick(row);
              }}
            />
            <DeleteButton onClick={() => handleDelete(row?._id)} />
          </Box>
        );
      },
    },
  ];

  function handlePagination(e, v) {
    setPage(v);
  }

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }} gap={"10px"}>
          <Typography variant="subtitle1">Offices</Typography>
          <form onSubmit={() => {}}>
            <TextField
              label="Search by Name"
              sx={{ width: "450px", padding: "0px" }}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              value={search}
              onChange={(e) => setSearch(e?.target?.value)}
            />
          </form>
        </Box>
        <AddItemButton
          title="Add New Office"
          func={() => {
            setFormData(null);
            toggleDrawer(true);
          }}
        />
      </Box>
      {isLoading || loading || !tableData ? (
        <Loader />
      ) : tableData?.length == 0 ? (
        <center>
          <EmptyScreen
            title={"Office"}
            word={"offices"}
            image={icons.EmptyOffice}
          />
        </center>
      ) : (
        <Box sx={{ marginTop: "20px" }}>
          <Table
            key={"officeConf"}
            dataSource={tableData}
            rowKey={"id"}
            columns={columns}
            loading={isLoading}
          />
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Pagination
              count={count > 10 ? Math.ceil(count / 10) : 1}
              variant="outlined"
              shape="rounded"
              onChange={handlePagination}
              page={page}
            />{" "}
          </Box>
        </Box>
      )}
      <Drawer
        anchor="right"
        open={open}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <StyledDrawerHeader>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Inter_semibold",
              color: "black",
            }}
          >
            {formData ? "Edit Office Name" : "Add Office Name"}
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontFamily: "Inter_semibold",
              color: "black",
              opacity: "0.5",
              cursor: "pointer",
            }}
            onClick={() => toggleDrawer(false)}
          >
            Cancel
          </Typography>
        </StyledDrawerHeader>
        <StyledDrawerContent>
          <Box sx={{ width: "550px" }}>
            <Form data={formData} toggleDrawer={toggleDrawer} />
          </Box>
        </StyledDrawerContent>
      </Drawer>
    </div>
  );
};

export default Offices;
