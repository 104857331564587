import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { http } from "../../api/http";
import Loader from "../../components/Loader";

const ReportingManager = ({ state, setState }: any) => {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (roles.length == 0) {
      setRoles(state);
    }
  }, [state]);

  const getRoles = () => {
    return http.get("/roles");
  };

  const getUsers = () => {
    return http.get("/users");
  };

  const { data: roleData, isLoading } = useQuery(["roles"], getRoles);
  const { data: userData, isLoading: userLoading } = useQuery(
    ["users"],
    getUsers
  );

  const handleChange = (value: any) => {
    setRoles(value);
    setState(value);
  };

  if (isLoading || userLoading) return <Loader />;
  return (
    <div>
      <Box mt={2}>
        <Autocomplete
          size="small"
          multiple
          onChange={(_, value) => handleChange(value)}
          value={roles}
          fullWidth
          options={roleData?.data?.data?.map((item: any) => ({
            label: item?.name,
            value: item?._id,
          }))}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Roles"
              variant="standard"
              required={roles.length > 0 ? false : true}
            />
          )}
        />
      </Box>
      <Details userData={userData} state={state} setState={setState} />
    </div>
  );
};

export default ReportingManager;

const Details = ({ userData, state, setState }: any) => {
  const [data, setData] = useState<any>(false);
  const handleChangeData = (value: any, i: any, index: any) => {
    let val = {
      ...state[index],
      manager: value,
      managerId: value.value,
      roleId: i.value,
      managerRole: "",
      managerRoleId: "",
    };

    state.splice(index, 1, val);
    setState(state);
    setData(!data);
  };

  const handleChange = (value: any, index: any) => {
    let val = {
      ...state[index],
      managerRole: value,
      managerRoleId: value.value,
    };

    state.splice(index, 1, val);
    setState(state);
    setData(!data);
  };

  return (
    <>
      {state.map((i: any, index: any) => (
        <Box
          p={2}
          mt={2}
          sx={{ border: "1px solid #E0E0E0", borderRadius: "5px" }}
        >
          <Typography>{`Reporting Manager for ${i.label}`}</Typography>
          <Box mt={2}>
            <Autocomplete
              size="small"
              onChange={(_, value) => handleChangeData(value, i, index)}
              value={state[index]?.manager}
              fullWidth
              options={userData?.data?.data?.map((item: any) => ({
                label: item?.name,
                value: item?._id,
              }))}
              renderInput={(params) => (
                <TextField {...params} label="Manager" variant="standard" />
              )}
            />
          </Box>
          {i?.managerId && (
            <Box mt={2}>
              <Autocomplete
                size="small"
                onChange={(_, value) => handleChange(value, index)}
                value={state[index]?.managerRole}
                fullWidth
                options={userData?.data?.data
                  .find((item: any) => item._id === i?.managerId)
                  ?.userRoles?.map((i: any) => ({
                    label: i?.role.name,
                    value: i?.role._id,
                  }))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Manager Role"
                    variant="standard"
                  />
                )}
              />
            </Box>
          )}
        </Box>
      ))}
    </>
  );
};
