import { icons } from "../../../assets";

export const menuItems = [
  {
    title: "TSSOCA Configuration",
    path: "/settings/tssoca-configuration",
    icon: icons.Config,
  },
  {
    title: "Crop types Configuration",
    path: "/settings/crop-types-configuration",
    icon: icons.CropsConfig,
  },
  {
    title: "Organization Role Configuration",
    path: "/settings/organization-role-configuration",
    icon: icons.OrgConfig,
  },
  {
    title: "Employee Configuration",
    path: "/settings/employee-configuration",
    icon: icons.EmpConfig,
  },
];

export const firstItems = [
  {
    title: "Financial Configuration",
    path: "/settings/tssoca-configuration/financial-configuration",
    icon: icons.FinConfig,
  },
  {
    title: "Season Configuration",
    path: "/settings/tssoca-configuration/season-configuration",
    icon: icons.SeasonConfig,
  },
  {
    title: "Section",
    path: "/settings/tssoca-configuration/section",
    icon: icons.Section,
  },
  {
    title: "Location",
    path: "/settings/tssoca-configuration/location",
    icon: icons.Location,
  },
  {
    title: "Office",
    path: "/settings/tssoca-configuration/office",
    icon: icons.Office,
  },
];

// export const headerTitle = [
//   {
//     title: "Dashboard",
//     path: "/dashboard",
//   },
//   {
//     title: "Incentive Programs",
//     path: "/incentive-programs",
//   },
//   {
//     title: "Payouts",
//     path: "/payouts",
//   },
//   {
//     title: "Employees",
//     path: "/employees",
//   },
//   {
//     title: "Disputes",
//     path: "/disputes",
//   },
//   {
//     title: "Reports",
//     path: "/reports",
//   },
//   {
//     title: "Master Data",
//     path: "/master-data",
//   },
//   {
//     title: "Source Data",
//     path: "/source-data",
//   },
// ];
