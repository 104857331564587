//@ts-nocheck
import { Box, Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { http } from "../../../api/http";

const Form = ({ data, toggleDrawer }: any) => {
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState();
  useEffect(() => {
    data && setEdit(true);
    data && setId(data?._id);
  }, []);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      name: data?.name,
    },
  });

  const queryClient = useQueryClient();

  const onSubmit = async (data: any) => {
    if (edit) {
      http
        .patch(`/sections/${id}`, data)
        .then((res) => {
          toast.success("The section has been updated");
          queryClient.invalidateQueries("confSection");
          toggleDrawer(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } else {
      http
        .post(`/sections`, data)
        .then((res) => {
          toast.success("The section has been created");
          queryClient.invalidateQueries("confSection");
          toggleDrawer(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };
  return (
    <div>
      {" "}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            type={"text"}
            {...register("name", {
              required: true,
              pattern: { value: /^[A-Za-z,\-&() .]+$/ },
            })}
            label={"Section Name"}
            required={true}
            fullWidth={true}
            variant={"standard"}
            sx={{ marginTop: "24px" }}
          />
          {errors?.name?.type == "required" && (
            <Typography sx={{ color: "red" }}>
              This field is required
            </Typography>
          )}
          {errors?.name?.type == "pattern" && (
            <Typography sx={{ color: "red" }}>Invalid Name</Typography>
          )}
          <Button type="submit" variant="contained" sx={{ marginTop: "24px" }}>
            Submit
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default Form;
