import { Delete, Edit } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteField, updatePage } from "../../../api/services/forms";
import { useConfirm } from "../../../context/ConfirmDialog";
import { selectForms, setData, setFocused } from "../../../redux/formsSlice";
import { StyledDraggebleFormField } from "../../../views/tasks/styles";
import FieldProperties from "../fields/FieldProperties";
import RenderField from "../utils/RenderField";

const PageFieldItem = ({ item, index, control }: any) => {
  const params = useParams();
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const [active, setActive] = useState<boolean>(false);
  const { data, focused } = useSelector(selectForms);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const { mutate } = useMutation(deleteField, {
    onSuccess: () => {
      toast.success("Field deleted");
      queryClient.invalidateQueries("form-details");
    },
    onError: (err: any) => {
      toast.error(err.response.data.message);
    },
  });

  const { mutate: updatePageFields } = useMutation(updatePage, {
    onSuccess: () => {
      toast.success("Page fields added");
      queryClient.invalidateQueries("form-details");
    },
    onError: (err: any) => {
      toast.error(err.response.data.message);
    },
  });

  const handleDelete = () => {
    confirm({
      msg: "Are you sure you want to delete this field?",
      action: () => {
        let { _id, ...newItem } = item;
        let fields = [...data?.fields];
        let index = fields.findIndex((field: any) => field._id === item._id);
        fields.splice(index, 1);

        // updatePageFields({
        //   formId: params.formId,
        //   pageId: data?._id,
        //   data: {
        //     fields,
        //   },
        // });
        const result = { ...data, fields: fields };
        dispatch(setData(result));
      },
    });
  };

  const handleCloneField = () => {
    let { _id, ...newItem } = item;

    let newObj = { ...newItem, _id: Math.random().toString() };
    let fields = [...data?.fields];
    let index = fields.findIndex((field: any) => field._id === item._id);
    fields.splice(index + 1, 0, newObj);

    // updatePageFields({
    //   formId: params.formId,
    //   pageId: data?._id,
    //   data: {
    //     fields,
    //   },
    // });

    const result = { ...data, fields: fields };
    dispatch(setData(result));
  };

  return (
    <>
      {data?.status == "Drafted" ? (
        <>
          <Draggable draggableId={item?._id} key={item?._id} index={index}>
            {(provided: any, snapshot: any) => (
              <StyledDraggebleFormField
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                draggablestyle={provided.draggableProps.style}
                active={active ? 1 : 0}
                onMouseOver={() => setActive(true)}
                onMouseLeave={() => setActive(false)}
                isdragging={snapshot.isDragging ? 1 : 0}
                focused={focused === item?._id ? 1 : 0}
              >
                <div className="field">
                  <RenderField item={item} control={control} />
                </div>
                <div
                  className="actions"
                  onMouseOver={(e) => e.stopPropagation()}
                >
                  <IconButton
                    sx={{ borderRadius: 0 }}
                    onClick={() => {
                      setOpen(true);
                      dispatch(setFocused(item?._id));
                    }}
                  >
                    <Edit color="primary" fontSize="small" />
                  </IconButton>
                  <IconButton
                    onClick={handleCloneField}
                    sx={{ borderRadius: 0 }}
                  >
                    <ContentCopyIcon color="primary" fontSize="small" />
                  </IconButton>
                  <IconButton onClick={handleDelete} sx={{ borderRadius: 0 }}>
                    <Delete color="primary" fontSize="small" />
                  </IconButton>
                </div>
              </StyledDraggebleFormField>
            )}
          </Draggable>
          <FieldProperties open={open} setOpen={setOpen} item={item} />
        </>
      ) : (
        <div
          className="field"
          style={{
            padding: "15px 10px",
            minHeight: "100px",
            border: "1px solid transparent",
            borderBottom: "1px solid #22222226",
          }}
        >
          <RenderField item={item} control={control} />
        </div>
      )}
    </>
  );
};

export default PageFieldItem;
