import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { icons } from "../../../assets";
import Loader from "../../../components/Loader";
import EmptyScreen from "../../KPIManagement/EmptyScreen";

const KPICards = ({
  cards,
  kpi,
  setKpi,
  isLoading,
  setSearchKpi,
  searchKpi,
}: any) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickPoints = () => {
    navigate("/employee-kpi-management/points");
    localStorage.setItem("kpiID", kpi?._id);
    localStorage.setItem("KPIStatus", kpi?.calculationFrequency);
  };

  const handleKpiClick = (item: any) => {
    setKpi(item);
  };

  useEffect(() => {
    if (cards?.length) {
      setKpi(cards[0]);
    }
  }, [cards, isLoading]);

  return (
    <div>
      <Box
        sx={{
          marginTop: "18px",
          padding: "20px",
          backgroundColor: "#fbf9f2",
          borderRadius: " 10px 10px 0px 0px;",
        }}
      >
        <TextField
          label="Search by KPI's"
          sx={{
            width: "100%",
            backgroundColor: "white",
            borderRadius: "5px",
          }}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          value={searchKpi}
          onChange={(e) => {
            setSearchKpi(e.target.value);
          }}
        />
      </Box>
      <Box
        overflow="auto"
        maxHeight="500px"
        className="scrollBox"
        sx={{
          border: "1px solid rgba(34, 34, 34, 0.1)",
          borderRadius: "0px 0px 5px 5px",
        }}
      >
        {isLoading ? (
          <Loader />
        ) : cards?.length == 0 ? (
          <center>
            <EmptyScreen image={icons.Kpi} />
          </center>
        ) : (
          cards?.map((item: any) => {
            return (
              <Box
                p={2}
                m={1.5}
                border={
                  kpi?._id == item?._id
                    ? "1px solid #00063A"
                    : "1px solid rgba(34, 34, 34, 0.1)"
                }
                borderRadius="5px"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  handleKpiClick(item);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography variant="h5" sx={{ fontSize: "16px" }}>
                      {item?.title}
                    </Typography>
                  </Box>
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <Box
                      sx={{
                        padding: "2px 4px",
                        border: "1px solid #00063A29",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MoreVertIcon sx={{ color: "grey" }} />
                    </Box>
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={handleClickPoints}>
                      <Box
                        sx={{ display: "flex", alignItems: "center" }}
                        gap={"10px"}
                      >
                        <VisibilityIcon
                          sx={{
                            color: "!important #00063A",
                            width: "18px",
                            height: "18px",
                          }}
                        />
                        <Typography
                          variant="h6"
                          sx={{ fontSize: "12px", color: "#00063A" }}
                        >
                          View Points
                        </Typography>
                      </Box>
                    </MenuItem>
                  </Menu>
                </Box>
                <Grid container sx={{ width: "100%" }}>
                  <Grid item xs={6} pt={2.5}>
                    <Typography
                      sx={{
                        fontFamily: "Inter_light",
                        fontSize: "12px",
                        opacity: "0.5",
                      }}
                    >
                      Points Allocated
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Inter_regular",
                        fontSize: "16px",
                      }}
                    >
                      {item?.pointsAllocated}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} pt={2.5}>
                    <Typography
                      sx={{
                        fontFamily: "Inter_light",
                        fontSize: "12px",
                        opacity: "0.5",
                      }}
                    >
                      Frequency
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Inter_regular",
                        fontSize: "16px",
                      }}
                    >
                      {item?.frequency}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} pt={2.5}>
                    <Typography
                      sx={{
                        fontFamily: "Inter_light",
                        fontSize: "12px",
                        opacity: "0.5",
                      }}
                    >
                      Updated on
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Inter_regular",
                        fontSize: "16px",
                      }}
                    >
                      {moment(item?.updatedAt).format("DD-MM-YYYY")}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} pt={2.5}>
                    <Typography
                      sx={{
                        fontFamily: "Inter_light",
                        fontSize: "12px",
                        opacity: "0.5",
                      }}
                    >
                      Grading Criteria
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Inter_regular",
                        fontSize: "16px",
                      }}
                    >
                      {item?.calculationFrequency}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            );
          })
        )}
      </Box>
    </div>
  );
};

export default KPICards;
