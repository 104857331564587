import {
  alpha,
  styled,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
} from "@mui/material";
// import TableFooter from 'components/Mui/TableFooter'
import _ from "lodash";

export interface ColumnProps {
  dataIndex: string;
  key: string;
  title: any;
  render?: (cellData: any, row: any) => any;
  textColor?: string;
}

interface TableProps {
  columns: Array<ColumnProps>;
  rowKey?: string;
  dataSource?: any[];
  loading?: boolean;
  onRowClick?: (row: any) => void;
  pagination?: {
    page: number;
    limit: number;
    totalCount: number;
    onChange: (v: number) => void;
    onChangeLimit?: (v: number) => void;
  };
}

export default function Table({
  columns,
  dataSource,
  onRowClick,
  pagination,
  loading,
}: TableProps) {
  return (
    <TableContainer
      sx={{
        borderRadius: "10px",
        border: "1px solid #1212121A",
        borderCollapse: "collapse",
        boxShadow: "0px 5px 15px #22222214",
      }}
    >
      <>
        <MuiTable sx={{ border: "none", borderCollapse: "collapse" }}>
          <TableHead sx={{ backgroundColor: "#F7F7F9" }}>
            <TableRow>
              {columns.map((col, index) => (
                <TableCell key={index}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontFamily: "Inter_semibold",
                      opacity: "0.5",
                    }}
                  >
                    {col.title}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {dataSource ? (
            <TableBody>
              {dataSource?.map((row, index) => (
                <StyledTableRow
                  canRowClick={!!onRowClick}
                  hover={!!onRowClick}
                  key={index}
                  onClick={() => {
                    return onRowClick && onRowClick(row);
                  }}
                >
                  {columns.map((col, colIndex) => (
                    <TableCell sx={{ color: col.textColor }} key={colIndex}>
                      {col?.render ? (
                        <Typography
                          sx={{
                            fontFamily: "Inter_regular",
                            fontSize: "14px",
                          }}
                        >
                          {col.render(row[col.dataIndex], row)}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            fontFamily: "Inter_regular",
                            fontSize: "14px",
                          }}
                        >
                          {_.get(row, col.dataIndex)}
                        </Typography>
                      )}
                    </TableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          ) : (
            <Box>
              <Typography sx={{ padding: "10px" }}>
                No Records Found !..
              </Typography>
            </Box>
          )}
        </MuiTable>
      </>
    </TableContainer>
  );
}

const StyledTableRow = styled(TableRow)<{ canRowClick: boolean }>(
  ({ theme, canRowClick }) => ({
    ...(canRowClick && {
      cursor: "pointer",
      "&.MuiTableRow-hover:hover": {
        backgroundColor: alpha("#f2f2f2", 0.4),
      },
    }),
  })
);
