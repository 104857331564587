//@ts-nocheck
import { Box } from "@mui/material";
import FormCheckbox from "../../../components/FormFields/FormCheckbox";
import FormInput from "../../../components/FormFields/FormInput";
import FormLimitRange from "../../../components/FormFields/FormLimitRange";
import FormOptions from "../../../components/FormFields/FormOptions";
import FormRadio from "../../../components/FormFields/FormRadio";
import FormSelect from "../../../components/FormFields/FormSelect";

interface Props {
  item: any;
  control: any;
  watch: any;
}

const Checkbox = (props: Props) => {
  const { control } = props;

  return (
    <>
      <Box mt={2}>
        <FormInput name="label" label="Question Title" control={control} />
      </Box>
      <Box mt={2}>
        <FormOptions name="options" control={control} label="Options" />
      </Box>
      <Box mt={2}>
        <FormRadio
          row
          control={control}
          name="required"
          label="Mandatory"
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
        />
      </Box>
    </>
  );
};

export default Checkbox;
