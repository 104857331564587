//@ts-nocheck
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { http } from "../../../api/http";

const CutoffDates = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState(false);
  const id = localStorage.getItem("itemId");
  const [details, setDetails] = useState([]);

  useEffect(() => {}, []);

  const { data, isLoading } = useQuery(["cropTypeConf"], () => {
    http
      .get(`/crops/${id}`)
      .then((res) => {
        setDetails(res?.data);
        localStorage.setItem("details", JSON.stringify(res?.data));
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  });

  let kharif = details?.seasons?.find((o) => o.name === "Kharif");
  let rabi = details?.seasons?.find((o) => o.name === "Rabi");

  return (
    <div>
      <Box
        sx={{ display: "flex", cursor: "pointer", width: "fit-content" }}
        onClick={() => navigate(-1)}
      >
        <KeyboardArrowLeftIcon />
        <Typography variant="subtitle2" sx={{ fontSize: "16px" }}>
          Back
        </Typography>
      </Box>
      <Box
        sx={{
          boxShadow: "0px 4px 16px #313B7E14",
          padding: "36px",
          display: "flex",
          alignItems: "center",
          borderRadius: "10px",
          marginTop: "30px",
        }}
        gap={"120px"}
      >
        <Grid container spacing={2}>
          <Grid item xs={2.5}>
            <Typography variant="h5" sx={{ fontSize: "16px" }}>
              Crop Type
            </Typography>
            <Typography
              variant="body1"
              sx={{ opacity: "0.5", marginTop: "10px" }}
            >
              {details?.name}
            </Typography>
          </Grid>
          <Grid item xs={2.5}>
            <Typography variant="h5" sx={{ fontSize: "16px" }}>
              Season
            </Typography>
            <Box sx={{ display: "flex", marginTop: "10px" }} gap={"6px"}>
              {kharif && (
                <Box
                  sx={{
                    padding: "7px 17px",
                    borderRadius: "5px",
                    backgroundColor: "#FFF6E8",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ opacity: "100%", color: "#FFA319" }}
                  >
                    Kharif
                  </Typography>
                </Box>
              )}
              {rabi && (
                <Box
                  sx={{
                    padding: "7px 17px",
                    borderRadius: "5px",
                    backgroundColor: "#EFF1FF",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ opacity: "100%", color: "#5E6EFF" }}
                  >
                    Rabi
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={2.5}>
            <Typography variant="h5" sx={{ fontSize: "16px" }}>
              Added on
            </Typography>
            <Typography
              variant="body1"
              sx={{ opacity: "0.5", marginTop: "10px" }}
            >
              {moment(details?.createdAt).format("DD-MM-YYYY")}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <nav
        style={{
          display: "flex",
          borderBottom: "1px solid #EEEEF2",
          marginTop: "28px",
        }}
      >
        {kharif && (
          <NavLink className={"masterDataNavLink"} to={"kharif"}>
            <Typography
              className="masterDataNavLinkText"
              sx={{
                fontSize: "12px",
                fontFamily: "Inter_semibold",
                opacity: "0.5",
              }}
            >
              Kharif
            </Typography>
            <Box id="borderBottom"></Box>
          </NavLink>
        )}
        {rabi && (
          <NavLink className={"masterDataNavLink"} to={"rabi"}>
            <Typography
              className="masterDataNavLinkText"
              sx={{
                fontSize: "12px",
                fontFamily: "Inter_semibold",
                opacity: "0.5",
              }}
            >
              Rabi
            </Typography>
            <Box id="borderBottom"></Box>
          </NavLink>
        )}
      </nav>

      <Outlet context={[form, setForm, details]} />
    </div>
  );
};

export default CutoffDates;
