import { icons } from "../../../assets";

export const menuItems = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: icons.Block,
  },
  {
    title: "KPI management",
    path: "/kpi-management",
    icon: icons.Kpi,
  },
  {
    title: "Employee KPI management",
    path: "/employee-kpi-management",
    icon: icons.Employee,
  },
  {
    title:"Reports",
    path:"/reports",
    icon: icons.Reports,
  }
];

// export const headerTitle = [
//   {
//     title: "Dashboard",
//     path: "/dashboard",
//   },
//   {
//     title: "Incentive Programs",
//     path: "/incentive-programs",
//   },
//   {
//     title: "Payouts",
//     path: "/payouts",
//   },
//   {
//     title: "Employees",
//     path: "/employees",
//   },
//   {
//     title: "Disputes",
//     path: "/disputes",
//   },
//   {
//     title: "Reports",
//     path: "/reports",
//   },
//   {
//     title: "Master Data",
//     path: "/master-data",
//   },
//   {
//     title: "Source Data",
//     path: "/source-data",
//   },
// ];
