import loadable from "@loadable/component";
import { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import PrimaryLayout from "../layout/adminLayout/primaryLayout";
import SettingsLayout from "../layout/adminLayout/settingsLayout";
import CropTypesConfiguration from "../views/CropTypesConfiguration";
import CutoffDates from "../views/CropTypesConfiguration/CutoffDateConfiguration";
import Details from "../views/CropTypesConfiguration/CutoffDateConfiguration/details";
import Dashboard from "../views/Dashboard";
import EmployeeConfiguration from "../views/EmployeeConfiguration";
import EmployeeKPIManagement from "../views/EmployeeKPIManagement";
import EmployeePerformance from "../views/EmployeeKPIManagement/EmployeePerformance";
import EmployeeRoles from "../views/EmployeeKPIManagement/EmployeeRoles";
import Points from "../views/EmployeeKPIManagement/Points";
import KPIManagement from "../views/KPIManagement";
import FormBuilder from "../views/KPIManagement/FormBuilder";
import KPIDetails from "../views/KPIManagement/KPIDetails";
import OrganizationRoleConfiguration from "../views/OrganizationRoleConfiguration";
import FinancialConfiguration from "../views/TSSOCAConfiguration/FinancialConfiguration";
import Locations from "../views/TSSOCAConfiguration/Location";
import Offices from "../views/TSSOCAConfiguration/Office";
import SeasonConfiguration from "../views/TSSOCAConfiguration/SeasonConfiguration";
import Sections from "../views/TSSOCAConfiguration/Sections";
import EmployeeAnnualPerformance from "../views/EmployeeKPIManagement/EmployeeAnnualPerformance";
import Reports from "../views/Reports";

const Login = loadable(() => import("../views/Login"));
// const ForgotPassword = loadable(() => import("../pages/ForgotPassword"));

const RoutesContainer = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const authenticated = localStorage.getItem("access_token") ? true : false;
    !authenticated && navigate("/login", { replace: true });
  }, []);

  return (
    <Routes>
      <Route path="/" element={<PrimaryLayout />}>
        <Route index element={<Navigate to="dashboard" />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="kpi-management" element={<KPIManagement />} />
        <Route path="kpi-management/details" element={<KPIDetails />} />
        <Route path="kpi-management/form-builder" element={<FormBuilder />} />
        <Route
          path="employee-kpi-management"
          element={<EmployeeKPIManagement />}
        />
         <Route
          path="reports"
          element={<Reports />}
        />
        <Route
          path="employee-kpi-management/roles"
          element={<EmployeeRoles />}
        />
        <Route
          path="employee-kpi-management/performance"
          element={<EmployeePerformance />}
        />
        <Route
          path="employee-kpi-management/annual-performance"
          element={<EmployeeAnnualPerformance />}
        />
        <Route path="employee-kpi-management/points" element={<Points />} />
      </Route>
      <Route path="settings" element={<SettingsLayout />}>
        <Route index element={<Navigate to="tssoca-configuration" />} />
        <Route path="tssoca-configuration">
          <Route
            path="financial-configuration"
            element={<FinancialConfiguration />}
          />
          <Route
            path="season-configuration"
            element={<SeasonConfiguration />}
          />
          <Route path="section" element={<Sections />} />
          <Route path="location" element={<Locations />} />
          <Route path="office" element={<Offices />} />
        </Route>
        <Route
          path="crop-types-configuration"
          element={<CropTypesConfiguration />}
        ></Route>
        <Route
          path="crop-types-configuration/cutoff-dates"
          element={<CutoffDates />}
        >
          <Route path="kharif" element={<Details />} />
          <Route path="rabi" element={<Details />} />
        </Route>
        <Route
          path="organization-role-configuration"
          element={<OrganizationRoleConfiguration />}
        />
        <Route
          path="employee-configuration"
          element={<EmployeeConfiguration />}
        />
      </Route>
      <Route path="login" element={<Login />} />
    </Routes>
  );
};

export default RoutesContainer;
