//@ts-nocheck
import { Box, Drawer, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";

import { http } from "../../api/http";
import { icons } from "../../assets";
import Breadcrumbs from "../../components/Breadcrumbs";
import {
  StyledDrawerContent,
  StyledDrawerHeader,
} from "../../components/DrawerTemplate";
import { EditButton } from "../../components/IconButtons";
import Loader from "../../components/Loader";
import PageHeader from "../../components/PageHeader";
import Table from "../../components/TableComponent";
import EmptyScreen from "../KPIManagement/EmptyScreen";
import Form from "./form";

const Points = () => {
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [open, setOpen] = useState(false);
  const kpiID = localStorage.getItem("kpiID");
  const userId = localStorage.getItem("empID");
  const KPIStatus = localStorage.getItem("KPIStatus");
  const toggleDrawer = (newOpen: any) => {
    setOpen(newOpen);
  };

  const handleEditClick = (row: any) => {
    setFormData(row);
    toggleDrawer(true);
  };

  const fetchAssets = async (id: any) => {
    const res = await http.get(`/points?userId=${userId}&kpiId=${id}`);

    setTableData(res?.data);
  };

  const { data, isLoading } = useQuery(["points", kpiID], () =>
    fetchAssets(kpiID)
  );

  const columns = [
    {
      title: "Grading Period",
      dataIndex:
        KPIStatus == "CutoffDate"
          ? "data.seasonName"
          : KPIStatus == "Monthly"
          ? "data.month"
          : "Annually",
      key:
        KPIStatus == "CutoffDate"
          ? "data.seasonName"
          : KPIStatus == "Monthly"
          ? "data.month"
          : "Annually",
    },
    {
      title: "Year",
      dataIndex: "financialYear.title",
      key: "financialYear.title",
    },
    {
      title: "Points Allocated",
      dataIndex: "data.points",
      key: "data.points",
    },
    {
      title: "Points Awarded",
      dataIndex: "data.pointsScored",
      key: "data.pointsScored",
    },
    {
      title: "Percentage",
      dataIndex: "data.percentage",
      key: "data.percentage",
      render: (colData, row) => {
        return Math.round(row?.data?.percentage * 100) / 100 + "%";
      },
    },

    {
      title: "Graded By",
      dataIndex: "data.updatedBy.name",
      key: "data.updatedBy.name",
    },
    {
      title: "Date",
      dataIndex: "data.updatedAt",
      key: "data.updatedAt",
      render: (row) => {
        return moment(row).format("DD-MM-YYYY");
      },
    },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_: any, row: any) => {
        return <EditButton onClick={() => handleEditClick(row)} />;
      },
    },
  ];

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              {
                name: "Employee Performance",
                to: "/employee-kpi-management/performance",
              },
              {
                name: "Employee Points",
                to: null,
              },
            ]}
          />
        }
      />
      {isLoading || !tableData ? (
        <Loader />
      ) : tableData?.length == 0 ? (
        <center style={{ marginTop: "-10px" }}>
          <EmptyScreen title={"Point"} word={"Points"} image={icons.Growth} />
        </center>
      ) : (
        <Box sx={{ marginTop: "20px" }}>
          <Table
            key={"points"}
            dataSource={tableData}
            rowKey={"id"}
            columns={columns}
            loading={isLoading}
          />
        </Box>
      )}
      <Drawer
        anchor="right"
        open={open}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <StyledDrawerHeader>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Inter_semibold",
              color: "black",
            }}
          >
            {"Edit Grading Points"}
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontFamily: "Inter_semibold",
              color: "black",
              opacity: "0.5",
              cursor: "pointer",
            }}
            onClick={() => toggleDrawer(false)}
          >
            Cancel
          </Typography>
        </StyledDrawerHeader>
        <StyledDrawerContent>
          <Box sx={{ width: "550px" }}>
            <Form data={formData} toggleDrawer={toggleDrawer} />
          </Box>
        </StyledDrawerContent>
      </Drawer>
    </div>
  );
};

export default Points;
