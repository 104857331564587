import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { icons } from "../../assets";
import Loader from "../../components/Loader";
import EmptyScreen from "../KPIManagement/EmptyScreen";

const PerformerTable = ({
  performers,
  loading,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setApply,
}: any) => {
  const [tableData, setTableData]: any[] = useState([]);
  const [updatesSort, setUpdatesSort] = useState("desc");
  const [totalPointsSort, setTotalPointsSort] = useState("desc");
  const [pointsSort, setPointsSort] = useState("desc");
  const [percentageSort, setPercentageSort] = useState("desc");

  const navigate = useNavigate();

  const StyledAvatar = styled(Typography)`
    ${({ theme }) => `
  cursor: pointer;
  background-color: #FFF;
  transition: ${theme.transitions.create(["#FFF", "transform"], {
    duration: 300,
  })};
  &:hover {
    background-color: #FFF;
    transform: scale(1.1);
  }
  `}
  `;

  useEffect(() => {
    if (performers && performers?.length != 0) {
      updatesSort == "desc"
        ? setTableData([
            ...performers.sort(
              (a: { updates: number }, b: { updates: number }) =>
                b.updates - a.updates
            ),
          ])
        : setTableData([
            ...performers.sort(
              (a: { updates: number }, b: { updates: number }) =>
                a.updates - b.updates
            ),
          ]);
    }
  }, [performers, updatesSort]);

  useEffect(() => {
    if (performers && performers?.length != 0) {
      pointsSort == "desc"
        ? setTableData([
            ...performers.sort(
              (a: { awardedPoints: number }, b: { awardedPoints: number }) =>
                b.awardedPoints - a.awardedPoints
            ),
          ])
        : setTableData([
            ...performers.sort(
              (a: { awardedPoints: number }, b: { awardedPoints: number }) =>
                a.awardedPoints - b.awardedPoints
            ),
          ]);
    }
  }, [performers, pointsSort]);

  useEffect(() => {
    if (performers && performers?.length != 0) {
      totalPointsSort == "desc"
        ? setTableData([
            ...performers.sort(
              (a: { totalPoints: number }, b: { totalPoints: number }) =>
                b.totalPoints - a.totalPoints
            ),
          ])
        : setTableData([
            ...performers.sort(
              (a: { totalPoints: number }, b: { totalPoints: number }) =>
                a.totalPoints - b.totalPoints
            ),
          ]);
    }
  }, [performers, totalPointsSort]);

  useEffect(() => {
    if (performers && performers?.length != 0) {
      percentageSort == "desc"
        ? setTableData([
            ...performers.sort(
              (a: { percentage: number }, b: { percentage: number }) =>
                b.percentage - a.percentage
            ),
          ])
        : setTableData([
            ...performers.sort(
              (a: { percentage: number }, b: { percentage: number }) =>
                a.percentage - b.percentage
            ),
          ]);
    }
  }, [performers, percentageSort]);

  if (loading) {
    return <Loader />;
  }

  const handleUpdatesSort = (type: any) => {
    type === "asc" ? setUpdatesSort("desc") : setUpdatesSort("asc");
  };

  const handleTotalPointsSort = (type: any) => {
    type === "asc" ? setTotalPointsSort("desc") : setTotalPointsSort("asc");
  };

  const handlePointsSort = (type: any) => {
    type === "asc" ? setPointsSort("desc") : setPointsSort("asc");
  };

  const handlePercentageSort = (type: any) => {
    type === "asc" ? setPercentageSort("desc") : setPercentageSort("asc");
  };

  const handleViewClick = (id: any) => {
    localStorage.setItem("empID", id);
    navigate("/employee-kpi-management/roles");
  };

  return (
    <div>
      <Box
        sx={{
          borderRadius: "10px",
          marginTop: "83px",
          boxShadow: "0px 4px 11px #26338B14;",
        }}
      >
        <Box
          sx={{
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            padding: "15px",
            backgroundColor: "#E9F1FC",
            border: "1px solid #0E0E0E1A",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ fontSize: "16px" }}>
            {"Employee Performance Metrics"}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }} gap={"10px"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ width: "190px", height: "50px" }}>
                <DatePicker
                  label="Select Start Date"
                  value={startDate}
                  maxDate={new Date()}
                  onChange={(newValue) => {
                    setEndDate(null);
                    setStartDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
              </Box>
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ width: "190px", height: "50px" }}>
                <DatePicker
                  label="Select End Date"
                  value={endDate}
                  minDate={startDate}
                  maxDate={new Date()}
                  disabled={!startDate}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
              </Box>
            </LocalizationProvider>
            <Button
              variant="contained"
              sx={{ height: "50px" }}
              disabled={!startDate || !endDate}
              onClick={() => {
                setApply(true);
              }}
            >
              <Typography sx={{ color: "white" }}>Apply</Typography>
            </Button>
          </Box>
        </Box>
        {performers?.length === 0 ? (
          <Box sx={{ marginTop: "30px" }}>
            <EmptyScreen image={icons?.Growth} marginTop={false} />
          </Box>
        ) : (
          <>
            <Box sx={{ backgroundColor: "white" }}>
              <Grid
                container
                spacing={1}
                sx={{ padding: "15px", marginTop: "1px" }}
              >
                <Grid item xs={2} textAlign="center">
                  <Typography variant="body1" sx={{ opacity: "0.7" }}>
                    Employee Name
                  </Typography>
                </Grid>
                <Grid item xs={2} textAlign="center">
                  <Typography variant="body1" sx={{ opacity: "0.7" }}>
                    Employee Role
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  textAlign="center"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  gap={"5px"}
                >
                  {updatesSort == "asc" ? (
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleUpdatesSort("asc")}
                    >
                      <img src={icons?.ArrowDown} />
                    </Box>
                  ) : (
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleUpdatesSort("desc")}
                    >
                      <img src={icons?.ArrowUp} />
                    </Box>
                  )}
                  <Typography variant="body1" sx={{ opacity: "0.7" }}>
                    Number of Updates
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  textAlign="center"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  gap={"5px"}
                >
                  {totalPointsSort == "asc" ? (
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleTotalPointsSort("asc")}
                    >
                      <img src={icons?.ArrowDown} />
                    </Box>
                  ) : (
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleTotalPointsSort("desc")}
                    >
                      <img src={icons?.ArrowUp} />
                    </Box>
                  )}
                  <Typography variant="body1" sx={{ opacity: "0.7" }}>
                    Points Allocated
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={2}
                  textAlign="center"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  gap={"5px"}
                >
                  {pointsSort == "asc" ? (
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => handlePointsSort("asc")}
                    >
                      <img src={icons?.ArrowDown} />
                    </Box>
                  ) : (
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => handlePointsSort("desc")}
                    >
                      <img src={icons?.ArrowUp} />
                    </Box>
                  )}
                  <Typography variant="body1" sx={{ opacity: "0.7" }}>
                    Points Awarded
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  textAlign="center"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  gap={"5px"}
                >
                  {percentageSort == "asc" ? (
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => handlePercentageSort("asc")}
                    >
                      <img src={icons?.ArrowDown} />
                    </Box>
                  ) : (
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => handlePercentageSort("desc")}
                    >
                      <img src={icons?.ArrowUp} />
                    </Box>
                  )}
                  <Typography variant="body1" sx={{ opacity: "0.7" }}>
                    Percentage
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                backgroundColor: "white",
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
                maxHeight: "500px",
                overflow: "scroll",
                overflowX: "hidden",
              }}
            >
              <Box>
                <Box p={1.5}>
                  {performers?.map((item: any, index: any) => (
                    <Box key={index}>
                      <Grid
                        container
                        spacing={1}
                        p={0}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          margin: "0px 12px",
                          borderBottom: "1px solid #EFEFEF",
                        }}
                      >
                        <Grid item xs={2} textAlign="center">
                          <Box
                            sx={{
                              padding: "32px 0px",
                              marginRight: "35px",
                              display: "flex",
                              gap: 2,
                            }}
                            onClick={() => handleViewClick(item?._id)}
                          >
                            <StyledAvatar
                              variant="body2"
                              sx={{
                                fontSize: "14px",
                                "&:hover": {
                                  cursor: "pointer",
                                },
                              }}
                            >
                              {item?.name}
                            </StyledAvatar>
                            <Box
                              sx={{
                                "&:hover": {
                                  cursor: "pointer",
                                },
                              }}
                            >
                              <img
                                src="resize.png"
                                width="18px"
                                height="18px"
                                alt=""
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={2} textAlign="center">
                          <Box
                            sx={{
                              padding: "32px 0px",
                              marginRight: "35px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "14px" }}
                            >
                              {item?.role}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={2} textAlign="center">
                          <Box
                            sx={{
                              padding: "32px 0px 32px 50px",
                              marginRight: "85px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "14px" }}
                            >
                              {item?.updates}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={2} textAlign="center">
                          <Box
                            sx={{
                              padding: "32px 0px 32px 50px",
                              marginRight: "85px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "14px" }}
                            >
                              {item?.totalPoints}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={2} textAlign="center">
                          <Box
                            sx={{
                              padding: "32px 0px 32px 34px",
                              marginRight: "85px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "14px" }}
                            >
                              {item?.awardedPoints}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={2} textAlign="center">
                          <Box
                            sx={{
                              padding: "32px 0px 32px 34px",
                              marginRight: "85px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "14px" }}
                            >
                              {Math.round(item?.percentage * 100) / 100 + "%"}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </div>
  );
};

export default PerformerTable;
