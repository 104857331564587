import { Box, Typography } from "@mui/material";
import React from "react";

const EmptyScreen = ({ image, title, word }: any) => {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "320px",
        }}
      >
        <img src={image} width="60px" height="60px" />
        <Typography
          variant="h4"
          sx={{ fontSize: "24px", marginTop: "15px" }}
        >{`There are no ${word} added`}</Typography>
        {title && (
          <Typography
            variant="subtitle2"
            sx={{ opacity: "0.7", marginTop: "6px" }}
          >{`Click on Add New ${title} to add a ${title}.`}</Typography>
        )}
      </Box>
    </div>
  );
};

export default EmptyScreen;
