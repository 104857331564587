//@ts-nocheck
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { http } from "../../api/http";

const Form = ({ data, toggleDrawer }: any) => {
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState();
  const ID = localStorage.getItem("roleId");

  useEffect(() => {
    data && setEdit(true);
    data && setId(data?._id);
  }, []);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      title: data?.title,
      pointsAllocated: data?.pointsAllocated,
      frequency: data?.frequency,
      calculationFrequency: data?.calculationFrequency,
      bufferDays: data?.bufferDays,
      kharifCutOffDate: data?.kharifCutOffDate,
      rabiCutOffDate: data?.rabiCutOffDate,
    },
  });

  const queryClient = useQueryClient();

  const gradingCriteria = watch("calculationFrequency");

  const onSubmit = (data: any) => {
    if (edit) {
      const formData = {
        ...data,
        roleId: ID,
        kharifCutOffDate: moment(data?.kharifCutOffDate).format("YYYY-MM-DD"),
        rabiCutOffDate: moment(data?.rabiCutOffDate).format("YYYY-MM-DD"),
      };

      http
        .patch(`/kpi/${id}`, formData)
        .then((res) => {
          toast.success("The details have been updated");
          queryClient.invalidateQueries("KPIs");
          toggleDrawer(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } else {
      const formData = {
        ...data,
        roleId: ID,
        kharifCutOffDate: moment(data?.kharifCutOffDate).format("YYYY-MM-DD"),
        rabiCutOffDate: moment(data?.rabiCutOffDate).format("YYYY-MM-DD"),
      };
      http
        .post(`/kpi`, formData)
        .then((res) => {
          toast.success("The KPI has been created");
          queryClient.invalidateQueries("KPIs");
          toggleDrawer(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          type={"text"}
          {...register("title", {
            required: true,
            pattern: { value: /^[ A-Za-z0-9()[]+.-*/ % /]*$/ },
          })}
          label={"KPI Title"}
          required={true}
          fullWidth={true}
          variant={"standard"}
          sx={{ marginTop: "24px" }}
        />
        {errors?.title?.type == "required" && (
          <Typography sx={{ color: "red" }}>This field is required</Typography>
        )}
        {errors?.title?.type == "pattern" && (
          <Typography sx={{ color: "red" }}>Invalid Name</Typography>
        )}
        <FormControl fullWidth sx={{ marginTop: "24px" }}>
          <TextField
            type={"number"}
            inputProps={{ min: 0 }}
            {...register("pointsAllocated", {
              required: true,
            })}
            variant={"standard"}
            label={"Points Allocated"}
            required={true}
          />
        </FormControl>
        {errors?.pointsAllocated && (
          <Typography
            sx={{
              fontFamily: "Inter_medium",
              color: "red",
            }}
          >
            This field is required
          </Typography>
        )}
        <FormControl fullWidth sx={{ marginTop: "24px" }}>
          <InputLabel id="frequency">Select Frequency *</InputLabel>
          <Select
            labelId="frequency"
            id="frequency"
            label="Frequency *"
            {...register("frequency", { required: true })}
            defaultValue={data?.frequency}
            required={true}
            variant={"standard"}
          >
            <MenuItem value={"Daily"}>{"Daily"}</MenuItem>
            <MenuItem value={"Weekly"}>{"Weekly"}</MenuItem>
            <MenuItem value={"Fortnightly"}>{"Fortnightly"}</MenuItem>
            <MenuItem value={"Monthly"}>{"Monthly"}</MenuItem>
            <MenuItem value={"SeasonStart"}>
              {"Once at the start of season"}
            </MenuItem>
            <MenuItem value={"SeasonEnd"}>
              {"Once at the end of season"}
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ marginTop: "24px" }}>
          <InputLabel id="calculationFrequency">
            Select Grading Criteria *
          </InputLabel>
          <Select
            labelId="grading"
            id="grading"
            label="Select Grading Criteria *"
            {...register("calculationFrequency", { required: true })}
            defaultValue={data?.calculationFrequency}
            required={true}
            variant={"standard"}
          >
            <MenuItem value={"Annually"}>{"Annually"}</MenuItem>
            <MenuItem value={"Monthly"}>{"Monthly"}</MenuItem>
            <MenuItem value={"CutoffDate"}>{"Cutoff date"}</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ marginTop: "24px" }}>
          <TextField
            type={"number"}
            inputProps={{ min: 0 }}
            {...register("bufferDays", {
              required: true,
            })}
            variant={"standard"}
            label={"Buffer Days"}
            required={true}
          />
        </FormControl>
        {errors?.bufferDays && (
          <Typography
            sx={{
              fontFamily: "Inter_medium",
              color: "red",
            }}
          >
            This field is required
          </Typography>
        )}
        {gradingCriteria == "CutoffDate" && (
          <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ marginTop: "24px" }}>
                <Controller
                  control={control}
                  name="kharifCutOffDate"
                  render={({
                    field: { ref, onBlur, name, ...field },
                    fieldState,
                  }) => {
                    return (
                      <DatePicker
                        {...field}
                        label="Kharif Cutoff Date"
                        inputFormat={"DD-MMM-YYYY"}
                        views={["year", "month", "day"]}
                        renderInput={(inputProps) => (
                          <TextField
                            {...inputProps}
                            helperText={null}
                            fullWidth={true}
                            required={true}
                            variant={"standard"}
                          />
                        )}
                        onChange={(value) => {
                          field.onChange(
                            moment(new Date(value)).format("YYYY-MM-DD")
                          );
                        }}
                      />
                    );
                  }}
                  defaultValue={moment(new Date())}
                />
              </Box>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ marginTop: "24px" }}>
                <Controller
                  control={control}
                  name="rabiCutOffDate"
                  render={({
                    field: { ref, onBlur, name, ...field },
                    fieldState,
                  }) => {
                    return (
                      <DatePicker
                        {...field}
                        label="Rabi Cutoff Date"
                        inputFormat={"DD-MMM-YYYY"}
                        views={["year", "month", "day"]}
                        renderInput={(inputProps) => (
                          <TextField
                            {...inputProps}
                            helperText={null}
                            fullWidth={true}
                            required={true}
                            variant={"standard"}
                          />
                        )}
                      />
                    );
                  }}
                  defaultValue={moment(new Date())}
                />
              </Box>
            </LocalizationProvider>
          </>
        )}
        <Button type="submit" variant="contained" sx={{ marginTop: "24px" }}>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default Form;
